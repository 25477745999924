import React, { useState } from 'react';
import {
  Typography, Box, MobileStepper, Button,
} from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import Layout from '../Layout/Layout'
import image1 from '../../images/01h.jpg'
import image2 from '../../images/02a.jpg'
import image3 from '../../images/03b.jpg'
import image4 from '../../images/04i.jpg'
import image5 from '../../images/05t.jpg'
import './TrainingModule.css'

const TrainingModule = () => {
  const trainingModules = [
    {
      label: 'Hunt for the Right Information',
      imgPath: image1,
    },
    {
      label: 'Answer the Right Question',
      imgPath: image2,
    },
    {
      label: 'Base Rate Awareness',
      imgPath: image3,
    },
    {
      label: 'Infer Wisely',
      imgPath: image4,
    },
    {
      label: 'Track and Update Your Forecasts',
      imgPath: image5,
    },
  ];

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = trainingModules.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Layout>
      <Typography className="topTitle">
        Training Module: Forecaster Basic Training
      </Typography>
      <Box className="moduleContainer">
        <Typography variant="h5">The SAGE training manual is divided into five parts:</Typography>
        <ol>
          {trainingModules.map((module) => (
            <Typography variant="h6"> <li key={module.label}>{module.label}</li></Typography>
          ))}
        </ol>

        <SwipeableViews
          axis="x"
          index={activeStep}
          onChangeIndex={(step) => setActiveStep(step)}
          enableMouseEvents
        >
          {trainingModules.map((step, index) => (
            <div key={step.label} className="imageContainer">
              { Math.abs(activeStep - index) <= 2 ? (
                <>
                  <Box component="img" className="moduleImage" src={step.imgPath} alt={step.label} />
                  <Typography
                    className="textOverImage"
                    variant="h6"
                    component="div"
                  >
                    {step.label}
                  </Typography>
                </>
              ) : null}
            </div>
          ))}
        </SwipeableViews>

        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={(
            <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1} className="nextButton">
              Next
              <KeyboardArrowRight />
            </Button>
          )}
          backButton={(
            <Button size="small" onClick={handleBack} disabled={activeStep === 0} className="backButton">
              <KeyboardArrowLeft />
              Back
            </Button>
          )}
          className="mobileStepper"
        />
      </Box>
    </Layout>
  );
};

export default TrainingModule;
