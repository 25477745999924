import getHeaders from '../helper/header'


const handleFetch = async (url, options = {}) => {
  const response = await fetch(url, options);
  if (response.status === 401) {
    throw new Error('Session expired');
  }
  if (!response.ok) {
    throw new Error('Network response was not ok.');
  }
  return response.json();
};


export const fetchQuestions = ({
  page,
  status,
  sort,
  startDate,
  endDate,
}) => {
  let url = '/api/forecasts/questions/list?'

  if (page !== undefined) {
    url += `page=${page}&`
  }
  if (status !== undefined) {
    url += `status=${status}&`
  }
  if (sort !== undefined) {
    url += `sort=${sort}&`
  }
  if (startDate !== undefined) {
    url += `start=${startDate}&`
  }
  if (endDate !== undefined) {
    url += `end=${endDate}&`
  }
  const headers = getHeaders()

  return handleFetch(url, { method: 'GET', headers });
}


export const fetchQuestionsByID = (questionId) => {
  const url = `/api/forecasts/questions/fetch/${questionId}`
  const headers = getHeaders()

  return handleFetch(url, { method: 'GET', headers });
}
