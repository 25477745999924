import React from 'react'
import CustomTimeline from './CustomTimeLine'

const ForecastTimeline = ({ question }) => {
  const data = [
    ...(question?.predictions || []),
    ...(Array.isArray(question?.comments) ? question?.comments ?? [] : []),

  ]

  return <CustomTimeline data={data} />
}

export default ForecastTimeline
