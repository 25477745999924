import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Typography,
  Pagination,
  Grid,
} from '@mui/material'
import { toast } from 'react-toastify'

import Layout from '../../../components/Layout/Layout'
import Question from '../../../components/Question/Question'
import ForecastingSlider from '../../../components/Slider/Slider'
import ForecastTimeline from '../../../components/Timeline/ForecastTimeline'
import { fetchQuestionsByID } from '../../../queries/questions.query'
import { isLoggedIn } from '../../../helper/auth'
import PageNotFound from '../PageNotFound/PageNotFound'
import { fetchTournamentByID } from '../../../queries/tournaments.query'


const QuestionNavigation = ({ previous, next }) => {
  const navigate = useNavigate();

  const handleNavigation = (questionId) => {
    if (questionId) {
      navigate(`/questions/${questionId}`);
    }
  };

  return (
    <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
      {previous && (
        <Button variant="outlined" color="primary" onClick={() => handleNavigation(previous)} style={{ marginRight: '5px' }}>
          Previous Question
        </Button>
      )}
      {next && (
        <Button variant="outlined" color="primary" onClick={() => handleNavigation(next)} style={{ marginLeft: '5px' }}>
          Next Question
        </Button>
      )}
    </Box>
  )
}


const Forecast = () => {
  const { questionid } = useParams()

  const { tournamentid } = useParams()

  const [question, setQuestion] = useState(null)
  const [prediction, setPrediction] = useState(null)
  const [navigation, setNavigation] = useState(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const { tournamentId } = useParams()
  const [questions, setQuestions] = useState(null)


  useEffect(() => {
    if (!tournamentid) return;
    window.scrollTo(0, 0)
    fetchTournamentByID(tournamentid)
      .then((data) => {
        setLoading(true)
        setQuestions(data?.tournament?.questions)
      }).catch((error) => {
        if (error.message === 'Session expired') {
          toast.error('Your session has expired! Please log in again.')
          localStorage.clear()
          navigate('/login')
        } else {
          toast.error(error.message || `Error fetching tournament with id ${tournamentId}`)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [tournamentId])

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchQuestionsByID(questionid)
      .then((data) => {
        setLoading(true)
        setQuestion(data?.question)
        setPrediction(data?.prediction)
        setNavigation(data?.tournament)
      })
      .catch((error) => {
        if (error.message === 'Session expired') {
          toast.error('Your session has expired! Please log in again.')
          localStorage.clear()
          navigate('/login')
        } else {
          toast.error( error.message || 'Error fetching questions by id')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [questionid])

  if (!isLoggedIn()) {
    localStorage.clear()
    navigate('/login')
    return null
  }

  if (loading) {
    return <Layout><PageNotFound message1="Loading..." /></Layout>
  }

  if (!question) {
    return (
      <Layout>
        <PageNotFound message={`Question With ID : ${questionid} have not been posted Yet`} />
      </Layout>
    )
  }

  const handlePaginationChange = (event, value) => {
    const id = questions[value - 1]?.id
    navigate(`/tournament/${tournamentid}/questions/${id}`);
    window.scrollTo(0, 0)
  };

  return (
    <Layout>
      <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
        <Question question={question} />
        <Typography variant="h4" style={{ paddingTop: '45px', fontWeight: 'bold' }}>
          Submit Forecast
        </Typography>
        <ForecastingSlider question={question} prediction={prediction} />
        <ForecastTimeline question={question} />
        <QuestionNavigation
          previous={navigation.previous}
          next={navigation.next}
        />

        <Grid container justifyContent="center" style={{ marginTop: 20, marginBottom: 20 }}>
          { tournamentid && (
          <Pagination
            count={questions?.length}
            page={questions && questions.findIndex(q => q?.id.toString() === questionid) + 1}
            onChange={handlePaginationChange}
            color="primary"
            showFirstButton
            showLastButton
          />
          )}
        </Grid>
      </div>
    </Layout>
  )
}

export default React.memo(Forecast)
