import React from 'react'
import Footer from './Footer/Footer'
import Header from './Header/Header'
import './Layout.css'


const Layout = ({ children }) => {
  return (
    <div className="layout-container">
      <Header />
      <div className="main-content">{children}</div>
      <Footer />
    </div>
  )
}


export default Layout
