import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from '@mui/material'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import PageNotFound from '../PageNotFound/PageNotFound'
import { fetchTournamentLeaderboard } from '../../../queries/tournaments.query'

const LeaderboardTable = () => {
  const { navigate } = useNavigate()
  const { tournamentId } = useParams()
  const [leaderboard, setLeaderboard] = useState([])
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const achievements = [{ icon: '🏆', count: 10 }, { icon: '🥈', count: 6 }, { icon: '🥉', count: 14 }]

  useEffect(() => {
    fetchTournamentLeaderboard(tournamentId)
      .then((response) => {
        setLoading(true)
        if (response.success === false) {
          // Handle case where success is false
          setErrorMessage(response.message)
        } else {
          setLeaderboard(response.leaderboard || [])
        }
      })
      .catch((error) => {
        if (error.message === 'Session expired') {
          toast.error('Your session has expired! Please log in again.')
          localStorage.clear()
          navigate('/login')
        } else {
          toast.error(error.message || `Error fetching tournament with id ${tournamentId}`)
          setErrorMessage(error.message || `Error fetching tournament with id ${tournamentId}`)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [tournamentId])

  if (loading) {
    return <PageNotFound message1="Loading..." />
  }

  if (errorMessage) {
    // Display the error message instead of the leaderboard


    return <Typography variant="body1">Questions have not been resolved yet!</Typography>
  }

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Rank</TableCell>
            <TableCell align="center">User</TableCell>
            <TableCell align="center">Badges</TableCell>
            <TableCell align="center">Tournament Brier</TableCell>
            <TableCell align="center">Average Brier</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leaderboard.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              <TableCell align="center">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <EmojiEventsIcon fontSize="small" style={{ verticalAlign: 'middle', marginRight: 4 }} />
                  <span style={{ verticalAlign: 'middle' }}>{row.rank}</span>
                </div>
              </TableCell>
              <TableCell align="center">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {row.user}
                  <Avatar sx={{
                    marginLeft: 1,
                    background: '#FFF',
                    width: 50,
                    height: 50,
                  }}
                  >
                    <img
                      src={`https://api.dicebear.com/7.x/rings/svg?seed=${row.user}`}
                      alt={`Identicon for ${row.user}`}
                      height="32"
                    />
                  </Avatar>
                </div>
              </TableCell>
              <TableCell align="center">
                {achievements.map((achievement, index) => (
                  <span key={index}>
                    {achievement.icon} x{achievement.count}
                  </span>
                ))}
              </TableCell>
              <TableCell align="center">{row.brier}</TableCell>
              <TableCell align="center">{row.avg_brier}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default LeaderboardTable
