import React from 'react'
import { Typography, Box } from '@mui/material'


const HorizontalBarGraph = ({ data }) => {
  const entries = Object.entries(data)
  const sortedEntries = entries.sort((a, b) => b[1] - a[1])

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
      }}
    >
      {sortedEntries.map(([key, value]) => (
        <Box
          key={key}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Typography
            variant="caption"
            sx={{
              width: '100%',
              textAlign: 'right',
              paddingRight: '24px',
              color: 'text.secondary',
            }}
          >
            {key}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '70%',
            }}
          >
            <Box
              sx={{
                backgroundColor: 'rgb(106, 63, 178)',
                width: `${value}%`,
                height: '10px',
                borderRadius: '5px',
                opacity: `${value}%`,
              }}
            />
            <Typography
              variant="caption"
              sx={{
                minWidth: '35px',
                marginLeft: '4px',
                color: 'text.secondary',
                textAlign: 'right',
              }}
            >
              {`${value}%`}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  )
}


export default HorizontalBarGraph
