import React, { useState } from 'react'
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Collapse,
  IconButton,
  Grid,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import './FaqList.css'

const FaqList = ({ faqs }) => {
  const [expanded, setExpanded] = useState(null)

  const handleExpandClick = (id) => {
    setExpanded(expanded === id ? null : id)
  }

  return (
    <List className="listPadding">
      {faqs && Object.keys(faqs)?.map((category) => (
        <React.Fragment key={category}>
          <Typography className="categoryHeader">{category}</Typography>
          {faqs[category].map((faq) => {
            const isExpanded = expanded === faq?.id
            return (
              <React.Fragment key={faq?.id}>
                <ListItem
                  onClick={() => handleExpandClick(faq?.id)}
                  className="listItem"
                >
                  <ListItemText
                    primary={(
                      <Typography className="title">
                        <strong>{faq?.title || 'Not Found'}</strong>
                      </Typography>
                    )}
                  />
                  <IconButton
                    onClick={() => handleExpandClick(faq?.id)}
                    aria-expanded={isExpanded}
                    aria-label="show more"
                    className={`expandButton ${isExpanded ? 'expandOpen' : ''}`}
                  >
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </ListItem>
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                  <Box className="collapseContent">
                    <Grid item xs={12} md={8} mt={3}>
                      <Typography style={{ fontSize: '1.4rem', marginBottom: '1em' }}>
                        {faq?.subtitle}
                      </Typography>
                      <Typography style={{ fontSize: '1.4rem', marginBottom: '1em' }}>
                        {faq?.description}
                      </Typography>
                    </Grid>
                  </Box>
                </Collapse>
              </React.Fragment>
            )
          })}
        </React.Fragment>
      ))}
    </List>
  )
}

export default React.memo(FaqList)
