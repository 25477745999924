import React from 'react';
import {
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const NewsCard = styled(Card)({
  display: 'flex',
  backgroundColor: '#000', // dark background
  marginBottom: '8px',
  '&:last-child': {
    marginBottom: 0,
  },
});

const NewsMedia = styled(CardMedia)({
  width: 300, // set image width
  backgroundSize: 'cover',
  height: 100,
});

const NewsDetails = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  color: '#fff', // text color white
});

const Title = styled(Typography)({
  fontWeight: 'bold',
  color: 'green', // title color green
});

const Description = styled(Typography)({
  color: '#fff', // description color white
});

const NewsContent = ({ title, description, imageUrl }) => (
  <NewsCard>
    <CardActionArea style={{ display: 'flex' }}>
      <NewsMedia image={imageUrl} title={title} />
      <NewsDetails>
        <Title variant="subtitle1" gutterBottom>
          {title}
        </Title>
        <Description variant="body2">
          {description}
        </Description>
      </NewsDetails>
    </CardActionArea>
  </NewsCard>
);

const VerticleNewsContent = ({ rightData }) => {
  return (
    <Grid container spacing={1} style={{ backgroundColor: '#000' }}>
      <Grid item xs={12} md={11}>

        {rightData?.map((item) => (

          <NewsContent
            title={item?.title}
            description={item?.subtitle}
            imageUrl={item?.images[0]?.image}
            newsUrl={item?.newsUrl}
          />

        ))}



      </Grid>
    </Grid>
  );
};

export default VerticleNewsContent;
