import { toast } from 'react-toastify'

const dateFormater = (date) => {
  try {
    const options = {
      month: 'long',
      day: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    };

    return new Date(date).toLocaleString('en-US', options);
  } catch (error) {
    toast.error('Error formatting date:', error);
    return 'Invalid Date';
  }
}


export default dateFormater
