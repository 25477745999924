import * as React from 'react'
import Box from '@mui/material/Box';
import { PieChart } from '@mui/x-charts/PieChart'


const transformData = (categorydata) => {
  const data = categorydata && Object.entries(categorydata)
    .map(([label, value], index) => {
      const truncatedLabel = label?.length > 10 ? `${label.slice(0, 8)} ...` : label
      return {
        id: index,
        value,
        label: truncatedLabel,
      }
    })
    .sort((a, b) => b.value - a.value)
  return data
}


export default function SemiArcPieChart({ categories }) {
  return (
    categories && (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 170,
          width: '100%',
        }}
      >
        <PieChart
          series={[
            {
              data: transformData(categories),
            },
          ]}
          width={360}
          height={170}
        />
      </Box>
    )
  );
}
