import { jwtDecode } from 'jwt-decode'
import { toast } from 'react-toastify'

export const handleToken = (accessToken) => {
  try {
    return jwtDecode(accessToken)
  } catch (error) {
    toast.error('Error decoding and storing user information:', error)
  }
}


export const handleTokenAndStoreUserInfo = (accessToken) => {
  try {
    const decodedToken = handleToken(accessToken)

    const userId = decodedToken.user_id
    const { name } = decodedToken
    const { username } = decodedToken
    const { exp } = decodedToken
    const currentTime = Math.floor(Date.now() / 1000);
    const isTokenExpired = exp < currentTime;
    localStorage.setItem('authToken', accessToken)
    localStorage.setItem('userId', userId)
    localStorage.setItem('name', name)
    localStorage.setItem('username', username)
    localStorage.setItem('tokenActive', !isTokenExpired)
  } catch (error) {
    toast.error('Error storing user information: in localstorage', error)
  }
}
