import React, { useState, useMemo } from 'react'
import { IconButton, InputBase, Paper } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import Fuse from 'fuse.js'

const SearchBar = ({ data, onSearch }) => {
  const [searchQuery, setSearchQuery] = useState('')

  // Options for the Fuse search
  const fuseOptions = {
    keys: ['title', 'subtitle', 'description', 'category'],
    threshold: 0.3, // Adjust the threshold based on your needs for fuzziness
  }

  // Creating the Fuse instance with memoization
  const fuse = useMemo(() => new Fuse(data, fuseOptions), [data])



  const handleSearchChange = (event) => {


    if (event.target.value.length === 0) {
      setSearchQuery('')
      window.location.reload()
    }
    setSearchQuery(event.target.value);

  }


  const executeSearch = (e) => {
    e.preventDefault();
    // If searchQuery is empty, reset the search results to the original data
    if (searchQuery.trim() === '') {
      onSearch(data);
    } else {
      const results = fuse.search(searchQuery).map(result => result.item);
      onSearch(results);
    }
  }

  return (
    <Paper
      component="form"
      onSubmit={executeSearch}
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '2px 20px',
        borderRadius: '10px',
        border: '3px solid #6a3fb2',
        boxShadow: 'none',
      }}
    >
      <InputBase
        sx={{
          flex: 1,
          fontSize: '1.7rem',
          '& .MuiInputBase-input': {
            height: '4rem',
            alignItems: 'center',
          },
        }}
        placeholder="Search..."
        inputProps={{ 'aria-label': 'search' }}
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <IconButton
        type="submit"
        aria-label="search"
        sx={{
          color: 'white',
          backgroundColor: '#6a3fb2',
          borderRadius: '80%',
          padding: '10px',
          '&:hover': {
            backgroundColor: '#9C27B0',
          },
          '& svg': {
            fontSize: '3rem',
          },
        }}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}

export default SearchBar;
