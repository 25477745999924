import React from 'react'
import EstimateControl from './EstimateControl'


const generateRandomData = (count, minPrice, maxPrice) => {
  const data = []
  const startDate = new Date('2023-01-01')
  for (let i = 0; i < count; i += 1) {
    const randomPrice = Math.random() * (maxPrice - minPrice) + minPrice
    const date = new Date(startDate)
    date.setMonth(startDate.getMonth() + i)
    data.push({ date, price: randomPrice })
  }
  return data
}


const stockData = generateRandomData(100, 20000, 26000)


const TimeSeriesChart = () => {
  return <EstimateControl data={stockData} />
}


export default TimeSeriesChart
