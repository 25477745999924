import * as React from 'react';
import { Paper, Button, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { Link } from 'react-router-dom';
import './FeaturedTournament.css';

const FeaturedTournament = ({ featuredTournaments }) => {
  return (
    <Carousel
      animation="slide"
      autoPlay
      interval={5000}
    >
      {featuredTournaments?.map((item, i) => (
        <Items key={i} item={item} />
      ))}
    </Carousel>
  );
};

const Items = ({ item }) => {
  return (
    <Paper
      sx={{
        marginTop: '5px',
        height: '60vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: `linear-gradient(to left, rgba(0,0,0,0.1), rgba(0,0,0,0.85)), url(${item.image})`,
        padding: '3rem',
        color: 'white',
      }}
      className="itemZoom"
    >
      <div className="home">
        <div className="headerContainer">
          <Typography variant="h3" className="typography">
            {item.title}
          </Typography>
          <Typography variant="h5" className="typography">
            Enter the {item?.title}
          </Typography>
          <Typography variant="h7" className="typography">
            {item.description} <br />
          </Typography>
          <Link to={`/tournament/${item.id}`}>
            <Button className="enterButton">ENTER NOW</Button>
          </Link>
        </div>
      </div>
    </Paper>
  );
};

export default FeaturedTournament;
