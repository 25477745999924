import React from 'react'
import { NavLink } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none',
  marginRight: theme.spacing(2),
  '&:hover': {
    color: '#673AB7',
  },
  '&.active': {
    color: theme.palette.primary.main,
  },
}));

const EmailLink = styled('a')(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const Footer = () => {
  return (
    <Box
      sx={{
        textAlign: 'center',
        bgcolor: '#FCFCFC',
        p: 3,
        color: 'text.secondary',
        borderTop: '1px solid #E0E0E0',
      }}
    >
      <Box
        component="ul"
        sx={{
          listStyle: 'none',
          display: 'flex',
          justifyContent: 'center',
          padding: 0,
          m: 0,
        }}
      >
        <StyledLink to="/">Home</StyledLink>
        <StyledLink to="/questions">Questions</StyledLink>
        <StyledLink to="/tournaments">Tournaments</StyledLink>
        <StyledLink to="/faq">FAQ</StyledLink>
      </Box>
      <Typography sx={{ mt: 2, mb: 2 }}>
        <EmailLink href="mailto:sage-info@isi.edu" style={{ textDecoration: 'none' }}>
          <img src="https://cdn-icons-png.flaticon.com/512/2099/2099199.png" alt="Email Icon" height="24" />
        </EmailLink>
      </Typography>
      <Typography>
        Copyright © 2024<br />
        University of Southern California
      </Typography>
    </Box>
  )
}

export default Footer
