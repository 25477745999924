import React from 'react'
import { Box, Typography } from '@mui/material'


const PageNotFound = ({ message1, message2 }) => {
  return (
    <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'calc(70vh)',
          color: '#6a3fb2',
        }}
      >
        <Typography variant="h3" gutterBottom>
          {message1}
        </Typography>
        <Typography variant="body1">{message2}</Typography>
      </Box>
    </div>
  )
}


export default PageNotFound
