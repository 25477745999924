import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import CssBaseline from '@mui/material/CssBaseline'
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from '@mui/material/styles'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import QuestionChart from './components/Charts/TimeSeries/TimeSeriesChart'
import QuestionPage from './containers/Pages/Questions/QuestionsPage'
import Forecast from './containers/Pages/Forecast/Forecast'
import Register from './containers/Pages/Register/Register'
import SignIn from './containers/Pages/SignIn/SignIn'
import Home from './containers/Pages/Home/Home'
// import ComingSoon from './containers/Pages/ComingSoon/ComingSoon'
import Account from './containers/Pages/Account/Account'
import Tournaments from './containers/Pages/Tournaments/Tournaments'
import TournamentDetail from './containers/Pages/Tournaments/TournamentDetail'
import FaqPage from './containers/Pages/Faq/FaqPage'
import NewsPage from './containers/Pages/News/NewsPage'
import TrainingModule from './components/Training/TrainingModule'


let theme = createTheme({
  palette: {
    primary: {
      main: '#673AB7', // purple
    },
    secondary: {
      main: '#9575CD', // green
    },
    background: {
      main: '#F5F5F5', // grey
      darkpurpletournament: '#0E0221',
    },
  },
  typography: {
    fontFamily: 'Poppins',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          WebkitFontSmoothing: 'auto',
        },
        body: {
          background: '#F5F5F5',
          color: '#333',
        },
        modal: {
          header: {
            fontSize: '26px',
            margin: '5px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: '100%',
          color: '#F5F5F5',
          background: '#6a3fb2',
          textDecoration: 'none',
          cursor: 'pointer',
          borderRadius: '5px',
          ':hover': {
            background: '#673AB7',
          },
        },
      },
    },
  },
})


theme = responsiveFontSizes(theme)


const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          backgroundColor: theme.palette.background.main,
          fontFamily: theme.typography.fontFamily,
        }}
      >
        <CssBaseline />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/training" element={<TrainingModule />} />
            {/* <Route path="/news" element={<ComingSoon />} /> */}
            <Route path="/training" element={<Home />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/questions" element={<QuestionPage />} />
            <Route
              path="/tournament/:tournamentid/questions/:questionid"
              element={<Forecast />}
            />
            <Route path="/questions/:questionid" element={<Forecast />} />
            <Route path="/question" element={<QuestionChart />} />
            <Route path="/account" element={<Account />} />
            <Route path="/tournaments" element={<Tournaments />} />
            <Route
              path="/tournament/:tournamentId"
              element={<TournamentDetail />}
            />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  )
}


export default App
