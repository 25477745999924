import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Divider,
  Card,
  CardContent,
  CardMedia,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PageNotFound from '../PageNotFound/PageNotFound';
import Layout from '../../../components/Layout/Layout';
import fetchNews from '../../../queries/news.query';
import { isLoggedIn } from '../../../helper/auth';
import VerticleNewsContent from './VerticleNewsContent';
import NewsTop from './NewsTop';

const styles = {
  topText: {
    padding: '8px 0px 3px 0px',
    fontSize: '45px',
    fontWeight: 'bold',
    fontFamily: 'Quub Black',
    opacity: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  updatedDate: {
    padding: '2px 0px 30px 0px',
    fontFamily: 'Quub Black',
    fontSize: '22px',
    fontWeight: 'bold',
    opacity: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentMiddle: {
    padding: '1% 0% 0% 15%',
  },
  divider: {
    padding: '1% 10% 0% 17%',
    width: '50px',
    color: 'black',
    bgcolor: 'brown',
    height: '5px',
  },
  contentBottom: {
    padding: '1% 0% 0% 15%',
  },
  textGrid: {
    padding: '1% 0% 0% 17%',
  },
  contactUs: {
    padding: '1% 30%',
  },

}

const ContentGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(0),
  marginBottom: theme.spacing(0),
}));


const ContentBlock = ({ topData, middleData }) => {
  return (
    <ContentGrid container style={styles.contentMiddle}>
      {topData.map((item) => (
        <NewsTop
          title={item?.title}
          description={item?.description}
          images={item?.images}
          newsUrl={item?.news_url}
        />
      ))}
      <Divider style={styles.divider} />
      <TextGrid1
        TextTitle="Investors bought a historic Echo Park home. Sisters who have lived there since childhood are fighting to stay"
        TextDescription="The home was one of the first in Echo Park. Lupe Breard and her sister have lived there for decades. But since their mom died, the family estate and a developer have tried to evict them."
      />
      {middleData.map((item) => (
        <ContentMiddle
          title={item?.title}
          description={item?.subtitle}
          imageUrl={item?.images[0]?.image}
          newsUrl={item?.news_url}
        />
      ))}

      <Divider style={styles.divider} />
      <TextGrid1
        TextTitle="Investors bought a historic Echo Park home. Sisters who have lived there since childhood are fighting to stay"
        TextDescription="The home was one of the first in Echo Park. Lupe Breard and her sister have lived there for decades. But since their mom died, the family estate and a developer have tried to evict them."
      />
    </ContentGrid>
  )
}

const ContentMiddle = ({
  title, description, imageUrl, newsUrl,
}) => (
  <Card sx={{
    maxWidth: 345, m: 2, borderRadius: '0px', boxShadow: 3,
  }}
  >
    <CardMedia
      component="img"
      height="250"
      image={imageUrl}
      alt={title}
      sx={{ objectFit: 'cover', cursor: 'pointer' }} // Add cursor style
      onClick={() => newsUrl && window.open(newsUrl, '_blank')}
    />
    <CardContent sx={{ bgcolor: 'rgba(0,0,0,0.7)', color: 'white' }}>
      <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
      <Typography variant="body2">
        {description}
      </Typography>
    </CardContent>
  </Card>

);

const GlowBorderCard = styled(Card)({
  position: 'relative',
  maxWidth: 200,
  margin: '0.5rem',
  borderRadius: '0px',
  overflow: 'visible',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: -10,
    left: -10,
    right: -10,
    bottom: -10,
    zIndex: -1,
    background: 'linear-gradient(65deg, rgba(255,0,0,0.8), rgba(255,255,0,0.8))',
    borderRadius: '5px',
    filter: 'blur(5px)',
  },
  boxShadow: '0 0 10px rgba(255,232,0,0.8), 0 0 20px rgba(0,0,0,0.8)', // Add some box-shadow for a glowing effect
});

const ContentBottom = ({ bottomData }) => {
  return (
    bottomData?.map((item) => (
      <GlowBorderCard>
        <CardMedia
          component="img"
          height="150"
          image={item?.images[0]?.image}
          alt={item?.title}
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {item?.title}
          </Typography>
        </CardContent>
      </GlowBorderCard>
    ))
  )
};


const TextGrid = ({ textData }) => {
  return (
    <Box style={styles.textGrid}>

      {textData?.map((item) => (
        <>
          <Divider sx={{ mb: 2 }} />
          <Typography variant="body2">{item?.title}</Typography>
          <Typography variant="body2" color="text.secondary">
            {item?.description}
          </Typography>
        </>
      ))}
    </Box>
  )
};

const TextGrid1 = ({ TextTitle, TextDescription }) => (
  <Box>
    <Divider sx={{ mb: 2 }} />
    <Typography variant="body2">{TextTitle}</Typography>
    <Typography variant="body2" color="text.secondary">
      {TextDescription}
    </Typography>

    <Divider sx={{ mb: 2 }} />
    <Typography variant="body2">{TextTitle}</Typography>
    <Typography variant="body2" color="text.secondary">
      {TextDescription}
    </Typography>
  </Box>
);

const NewsPage = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true);
  const [newsByPosition, setNewsByPosition] = useState({
    top: [],
    middle: [],
    right: [],
    bottom: [],
    others: [],
  });

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchNews()
      .then((data) => {
        const filteredNews = {
          top: [],
          middle: [],
          right: [],
          bottom: [],
          others: [],
        };
        data?.questions?.forEach(item => {
          const imagePosition = item.images.length > 0 ? item.images[0].position.toUpperCase() : 'OTHERS';
          switch (imagePosition) {
            case 'TOP':
              filteredNews.top.push(item);
              break;
            case 'MIDDLE':
              filteredNews.middle.push(item);
              break;
            case 'RIGHT':
              filteredNews.right.push(item);
              break;
            case 'BOTTOM':
              filteredNews.bottom.push(item);
              break;
            default:
              filteredNews.others.push(item);
          }
        });

        setNewsByPosition(filteredNews);
      })
      .catch(error => {
        if (error.message === 'Session expired') {
          toast.error('Your session has expired! Please log in again.')
          localStorage.clear()
          navigate('/login')
        } else {
          toast.error(error.message || 'Error fetching News')
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Layout>
      {isLoggedIn() && !loading ? (
        <div>
          <Typography style={styles.topText}>
            SAGE NEWS
          </Typography>


          <Grid container>
            <Grid item sx={8} md={8} lg={9}>

              <ContentBlock topData={newsByPosition?.top} middleData={newsByPosition?.middle} />

              <ContentGrid container style={styles.contentBottom}>
                <ContentBottom bottomData={newsByPosition?.bottom} />
              </ContentGrid>
              {/* ------------------------------------------------- */}
              <TextGrid textData={newsByPosition?.others} />
            </Grid>
            <Grid item sx={1} md={1} lg={2.8}>
              <VerticleNewsContent rightData={newsByPosition?.right} />
            </Grid>
          </Grid>
        </div>
      ) : (
        <PageNotFound
          message1="Please Login First !!"
          message2="This page is only accessible to authenticated users"
        />
      )}
    </Layout>
  )
}

export default React.memo(NewsPage)
