import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  Container, Grid, Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import ResearchImage from '../../../images/research.svg'
import PlatfromImage from '../../../images/platform.svg'
import ParticipantImage from '../../../images/participant.svg'
import { fetchFeaturedTournament } from '../../../queries/tournaments.query'
import FeaturedTournament from '../Tournaments/FeaturedTournament/FeaturedTournament'
import './Home.css';

const SageInfo = () => {

  return (
    <Container className="container">
      <Typography variant="h4" className="marginBottom1em">
        WELCOME TO THE SAGE HYBRID FORECASTING PLATFORM
      </Typography>
      <br />
      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <img src={ResearchImage} alt="Research" className="image250px" />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" className="strongWeight marginBottom1em">
            What is the SAGE platform?
          </Typography>
          <Typography className="fontSize1rem marginBottom1em">
            SAGE is a team of social and computer scientists working together to
            create better tools to predict the future.
          </Typography>
          <Typography className="fontSize1rem marginBottom1em">
            The SAGE team is working to build a hybrid forecasting platform that
            utilizes the unique strengths of both humans and machine models.
          </Typography>
          <Typography variant="h6" style={{ fontWeight: '600' }}>
            What topics are covered on the SAGE platform?
          </Typography>
          <Typography className="fontSize1rem marginBottom1em">
            SAGE predictions are primarily focused on global politics,
            economics, science, business, and entertainment.
          </Typography>
        </Grid>
      </Grid>

      <br />

      <Grid container spacing={5}>
        <Grid item xs={12} md={9}>
          <Typography variant="h6" className="strongWeight marginBottom1em">
            Why participate?
          </Typography>
          <Typography className="fontSize1rem marginBottom1em">
            Our data tools can help you become a better forecaster. And your
            insights can help our models learn. Enter a tournament to win
            prizes, like gift cards.
          </Typography>
          <Typography variant="h6" className="strongWeight marginBottom1em">
            How is SAGE different from other forecasting sites or prediction
            markets?
          </Typography>
          <Typography className="fontSize1rem marginBottom1em">
            SAGE uses unique data tools and machine learning methods to
            “hybridize” the forecasting process. We’re not a prediction or
            betting market: SAGE is free to enter, so you’re never betting your own
            money.
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <img src={PlatfromImage} alt="Platform" className="image250px" />
        </Grid>
      </Grid>

      <br />
      <Grid container spacing={5}>
        <Grid item xs={12} md={5}>
          <img src={ParticipantImage} alt="Participant" className="image250px" />
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography variant="h6" className="strongWeight marginBottom1em">
            What’s next?
          </Typography>
          <Typography className="fontSize1rem marginBottom1em">
            There are always several questions open to choose from. Having
            trouble deciding? Enter a mini-tournament. Mini-tournaments are
            thematic sets of questions where you can often win prizes.
          </Typography>
          <Typography variant="h6" className="strongWeight marginBottom1em">
            New to forecasting?
          </Typography>
          <Typography className="fontSize1rem marginBottom1em">
            View our training to learn the basics of good forecasting. If
            you have questions, see our FAQs.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

const Home = () => {
  const navigate = useNavigate()
  const [featuredTournaments, setFeaturedTournaments] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchFeaturedTournament()
      .then((data) => {
        setLoading(true)
        setFeaturedTournaments(data?.tournaments)
      })
      .catch((error) => {
        if (error.message === 'Session expired') {
          toast.error('Your session has expired! Please log in again.')
          localStorage.clear()
          navigate('/login')
        } else {
          toast.error(error.message || 'Error Fetching Featured Tournament')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])
  return (
    <Layout>
      {!loading && <FeaturedTournament featuredTournaments={featuredTournaments} />}
      <SageInfo />
    </Layout>
  );
};

export default Home
