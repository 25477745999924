export const getToken = () => localStorage.getItem('authToken')
export const isLoggedIn = () => !!localStorage.getItem('authToken')
export const tokenActive = () => localStorage.getItem('tokenActive')

export const getUserName = () => {
  const name = localStorage?.getItem('username')
  return name
}


export const logout = () => {
  localStorage.clear()
  window.location.reload()
}
