import getHeaders from '../helper/header'


const handleFetch = async (url, options = {}) => {
  const response = await fetch(url, options);
  if (response.status === 401) {
    throw new Error('Session expired');
  }
  if (!response.ok) {
    throw new Error('Network response was not ok.');
  }
  return response.json();
};


const fetchProfile = () => {
  const url = '/api/accounts/profile/'
  const headers = getHeaders()

  return handleFetch(url, { method: 'GET', headers });
}

export default fetchProfile
