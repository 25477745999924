import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'


const EstimateControl = ({ data }) => {
  const svgRef = useRef()

  useEffect(() => {
    const margin = {
      top: 20,
      right: 30,
      bottom: 40,
      left: 50,
    }
    const width = 1600 - margin.left - margin.right
    const height = 600 - margin.top - margin.bottom

    const svg = d3.select(svgRef.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    // Define your data parsing and scaling here

    const xScale = d3.scaleTime()
      .domain(d3.extent(data, d => d.date))
      .range([0, width])

    const yScale = d3.scaleLinear()
      .domain([18000, d3.max(data, d => d.price)])
      .nice()
      .range([height, 0])

    // Create X and Y axes
    const xAxis = d3.axisBottom(xScale)
    const yAxis = d3.axisLeft(yScale)

    svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(xAxis)

    svg.append('g')
      .call(yAxis)


    // Create the line generator
    const line = d3.line()
      .x(d => xScale(d.date))
      .y(d => yScale(d.price))

    // Draw the line chart
    svg.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 1.5)
      .attr('d', line)

    // Add dotted horizontal lines at regular intervals
    const numLines = 10 // Adjust the number of lines as needed
    for (let i = 1; i < numLines; i += 1) {
      const yValue = 18000 + (i * 1000) // Adjust the interval (800) as needed
      svg.append('line')
        .attr('x1', 0)
        .attr('x2', width)
        .attr('y1', yScale(yValue))
        .attr('y2', yScale(yValue))
        .attr('stroke', 'gray')
        .attr('stroke-dasharray', '4') // Dotted line style
    }

  }, [data])

  return <svg ref={svgRef} />
}


export default EstimateControl
