import React from 'react';
import {
  Typography,
  List,
  ListItem,
  Box,
} from '@mui/material';
import { Link } from 'react-router-dom';
import QuestionPlaceholderImage from '../../../images/question_placeholder_image.png';
import dateFormater from '../../../helper/utils';

const styles = {
  listItem: {
    display: 'flex',
    padding: '16px',
    borderRadius: '10px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    marginBottom: '20px',
    backgroundColor: '#FCFCFC',
    alignItems: 'flex-start',
    overflow: 'hidden',
    flexDirection: 'column',
    '@media (min-width: 600px)': {
      flexDirection: 'row',
    },
    '&:hover': {
      boxShadow: '0px 2px 4px rgba(103, 58, 183, 0.35)',
    },
  },
  imageContainer: {
    borderRadius: '10px',
    objectFit: 'cover',
    width: '100%',
    height: 'auto',
    '@media (min-width: 600px)': {
      width: '240px',
      height: '160px',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    height: '160px',
    boxSizing: 'border-box',
    paddingTop: '16px',
    '@media (min-width: 600px)': {
      paddingTop: '0px',
      paddingLeft: '16px',
    },
  },
  title: {
    color: '#3F256A',
    fontWeight: 'bold',
    fontSize: '1rem',
    '@media (min-width: 600px)': {
      fontSize: '1.15rem',
      marginBottom: '16px',
      WebkitLineClamp: 2,
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  detailText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: '4em',
    paddingTop: '16px',
    '& .MuiTypography-root': {
      fontWeight: 'bold',
      color: '#3F256A',
      fontSize: '1rem',
      '& span': {
        fontWeight: 'normal',
        color: '#000000',
        fontSize: '1rem',
        '@media (max-width: 599px)': {
          fontSize: '0.85rem',
        },
      },
      '@media (max-width: 599px)': {
        fontSize: '0.85rem',
      },
    },
  },
  bottomDetails: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '@media (min-width: 600px)': {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      marginTop: 'auto',
    },
  },
  status: {
    marginLeft: 'auto',
    fontWeight: 'bold',
    color: '#3F256A',
  },
};



const QuestionList = ({ questions, searchQuery }) => {
  let renderedQuestions = questions;
  // const theme = useTheme();
  if (searchQuery) {
    const filteredQuestions = questions.filter(
      question => question?.title?.toLowerCase().includes(searchQuery.toLowerCase()),
    );
    renderedQuestions = filteredQuestions;
  }
  return (
    <List style={{ marginTop: '20px' }}>
      {renderedQuestions?.map((question) => (
        <ListItem
          key={question.id}
          component={Link}
          to={`/questions/${question.id}`}
          sx={styles.listItem}
        >
          <Box
            component="img"
            src={question.image || QuestionPlaceholderImage}
            alt="Question"
            sx={styles.imageContainer}
          />
          <Box sx={styles.contentContainer}>
            <Typography sx={styles.title}> {question.title.slice(0, 150)}
              {question.title.length > 150 ? '...' : ''}
            </Typography>
            <Box sx={styles.detailText}>
              <Typography>Comments: <span>{question.comments}</span></Typography>
              <Typography>Forecasters: <span>{question.forecasters}</span></Typography>
              <Box sx={styles.bottomDetails}>
                <Typography>
                  Closing: <span>{dateFormater(question.ending_at)}</span>
                </Typography>
                <Typography sx={styles.status}>
                  Status: <span style={{ color: question?.status === 'OPEN' ? '#2ece57' : '#34aadc', fontWeight: 'bold' }}>{question.status}</span>
                </Typography>
              </Box>
            </Box>
          </Box>
        </ListItem>
      ))}
    </List>
  );
};

export default QuestionList;
