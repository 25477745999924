import getHeaders from '../../helper/header'


const postForecast = (data) => {
  const url = '/api/forecasts/predict/'
  const headers = getHeaders()

  return new Promise((resolve, reject) => {
    fetch(url, { method: 'POST', headers, body: JSON.stringify(data) })
      .then((response) => response.json())
      .then((responseData) => resolve(responseData))
      .catch((error) => reject(error))
  })
}

export default postForecast
