import React, { useState } from 'react'
import { Typography, Button } from '@mui/material'
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from '@mui/lab'

import CustomTimelineItem from './CustomTimeLineItem'

const styles = {
  timelineContainer: {
    height: '100vh',
    overflowY: 'auto',
    padding: '0 16px',
  },
  timelineItem: {
    '&:before': {
      display: 'none',
    },
  },
  timelineContent: {
    padding: '16px',
    paddingLeft: '24px',
  },
  timelineDot: {
    color: '#3f51b5',
    padding: '8px',
  },
  timelineConnector: {
    backgroundColor: '#9c27b0',
  },
  timelineOppositeContent: {
    flex: 0.1,
    padding: '0 16px',
  },
  rationale: {
    fontStyle: 'italic',
    fontSize: '0.8rem',
  },
}

const CustomTimeline = ({ data }) => {
  const [showAll, setShowAll] = useState(false)

  const sortedRecords = data.slice().sort((a, b) => {
    const dateA = new Date(a?.created_at)
    const dateB = new Date(b?.created_at)
    return dateB - dateA
  })

  const groupedData = {}
  sortedRecords.forEach((item) => {
    const date = new Date(item.created_at).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })

    if (!groupedData[date]) {
      groupedData[date] = { comments: [], predictions: [] }
    }

    if ('comment' in item) {
      groupedData[date].comments.push(item)
    } else if ('answer' in item) {
      groupedData[date].predictions.push(item)
    }
  })

  return (
    <Timeline align="left" style={styles.timelineContainer}>
      {Object.keys(groupedData).map((date, index) => (
        <React.Fragment key={index}>
          {groupedData[date].comments.map((comment, subIndex) => (
            <CustomTimelineItem
              key={subIndex}
              date={date}
              content={{
                username: comment.username,
                comment: comment.comment,
              }}
              comment={comment}
            />
          ))}

          {groupedData[date]?.predictions?.length > 8 && !showAll ? (
            <TimelineItem style={styles.timelineItem}>
              <TimelineOppositeContent style={styles.timelineOppositeContent}>
                <Typography color="textSecondary">{date}</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot style={styles.timelineDot} />
                <TimelineConnector style={styles.timelineConnector} />
              </TimelineSeparator>
              <TimelineContent style={styles.timelineContent}>
                <Typography variant="subtitle2">
                  {groupedData[date].predictions?.length} users made forecasts
                </Typography>
                <Button onClick={() => setShowAll(true)}>Show All</Button>
              </TimelineContent>
            </TimelineItem>
          ) : groupedData[date].predictions.map((prediction, subIndex) => (
            <CustomTimelineItem
              key={subIndex}
              date={date}
              content={{
                username: prediction.username,
                comment: 'Made a forecast',
              }}
              rationale={prediction.rationale}
              barGraphData={prediction.answer}
            />
          ))}
        </React.Fragment>
      ))}
    </Timeline>
  )
}

export default CustomTimeline
