import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Slider from '@mui/material/Slider'
import Grid from '@mui/material/Grid'
import { Typography, Button, TextField } from '@mui/material'
import Paper from '@mui/material/Paper'
import { toast } from 'react-toastify'

import postForecast from '../../mutations/forecast/forecast.mutation'
import { postComment } from '../../mutations/forecast/comment.mutation'


const ForecastingSlider = ({ question, prediction }) => {
  const possibilities = question?.options?.map((option) => {
    return {
      id: option.id,
      text: option.text,
      percent: 0,
    }
  })

  const { questionid } = useParams()
  const [percentages, setPercentages] = useState(possibilities.map(() => 0))
  const [prevPercentages, setPrevPercentages] = useState(possibilities.map(() => 0))
  const [forecastRationale, setForecastRationale] = useState('')
  const [comments, setComments] = useState('')

  useEffect(() => {
    if (prediction) {
      const initialPercentages = possibilities.map((possibility) => {
        return prediction.answer[possibility.text] || 0
      })
      setPrevPercentages(initialPercentages)
    }
  }, [prediction, possibilities])

  const handleForecastSubmit = () => {
    const answer = {
      user: localStorage.getItem('userId'),
      question: parseInt(questionid, 10),
      answer: {},
      rationale: forecastRationale,
    }

    possibilities?.forEach((possibility, index) => {
      answer.answer[possibility.id] = percentages[index]
    })


    postForecast(answer)
      .then(() => {
        window.location.reload()
      })
      .catch((error) => {
        toast.error('Error posting forecast', error)
      })
  }

  const handlCommentSubmit = () => {
    const comment = {
      question: parseInt(questionid, 10),
      comment: comments,
    }

    postComment(comment)
      .then((result) => {
        if (result.success === true) {
          toast.success('Comment posted successfully')
          window.location.reload()
        } else {
          toast.error('Error posting forecast', result)
        }
      })
      .catch((error) => {
        toast.error('Error posting forecast', error)
      })
  }

  const handleSliderChange = (index, value) => {
    const updatedPercentages = percentages.map((percent, i) => {
      return i === index ? value : percent
    })

    const newTotalPercent = updatedPercentages.reduce((a, b) => a + b, 0)

    if (newTotalPercent <= 100) {
      setPercentages(updatedPercentages)
    } else {
      const adjustedValue = value - (newTotalPercent - 100)
      updatedPercentages[index] = adjustedValue
      setPercentages(updatedPercentages)
    }
  }

  const handleInputChange = (index, value) => {
    const parsedValue = parseFloat(value)

    if (!Number.isNaN(parsedValue) && parsedValue >= 0) {
      const updatedPercentages = [...percentages]
      const newTotalPercent = updatedPercentages.reduce((a, b) => a + b, 0)
      if (newTotalPercent + parsedValue - updatedPercentages[index] <= 100) {
        updatedPercentages[index] = parsedValue
        setPercentages(updatedPercentages)
      }
    }
  }

  const totalPercent = percentages.reduce((a, b) => a + b, 0)
  const isTotalValid = totalPercent === 100

  return (
    <>
      <Paper
        elevation={3}
        style={{ padding: '20px', backgroundColor: 'white', marginTop: '20px' }}
      >
        <Grid
          container
          alignItems="center"
          style={{ paddingBottom: '20px', fontWeight: 700 }}
        >
          <Grid item xs={10}>
            <strong>Possible Answer</strong>
          </Grid>
          <Grid item xs={1} style={{ paddingLeft: '10px' }}>
            <strong>Current</strong>
          </Grid>
          <Grid item xs={1}>
            <strong>Previous</strong>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container spacing={2}>
            {possibilities?.map((possibility, index) => (
              <Grid item xs={12} key={index}>
                <Typography variant="subtitle1">{possibility.text}</Typography>
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    <Slider
                      value={percentages[index]}
                      onChange={(e, value) => handleSliderChange(index, value)}
                      onChangeCommitted={(e, value) => handleSliderChange(index, value)}
                      min={0}
                      max={100}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) => `${value}%`}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      value={percentages[index]}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      onBlur={() => handleSliderChange(index, percentages[index])}
                      type="number"
                      variant="outlined"
                      inputProps={{ min: 0, max: 100 }}
                      style={{ margin: '0 20px', width: '80%' }}
                    />
                  </Grid>
                  <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                      {`${prevPercentages[index]}%`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <div
          style={{
            marginBottom: '20px',
          }}
        />
        <Typography
          variant="h6"
          style={{
            color: totalPercent === 100 ? 'green' : 'red',
            float: 'right',
          }}
        >
          Total Percentage: {totalPercent}%
        </Typography>
        <Typography style={{ color: 'red', fontSize: '16px' }}>
          {isTotalValid ? null : (
            <>Forecast probabilities should sum up to 100%!</>
          )}
        </Typography>
        <TextField
          style={{ fontSize: '30px' }}
          label="Please explain the rationale behind your forecast."
          fullWidth
          multiline
          rows={4}
          value={forecastRationale}
          onChange={(e) => setForecastRationale(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '20px' }}
          onClick={handleForecastSubmit}
          disabled={!isTotalValid}
        >
          Submit Forecast
        </Button>
      </Paper>

      <div style={{ paddingTop: '20px', paddingBottom: '10px' }}>
        <Paper
          style={{
            padding: '20px',
            backgroundColor: 'white',
            marginTop: '10px',
          }}
        >
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={11}>
              <TextField
                style={{ fontSize: '20px', flexGrow: 1 }}
                label="Post a comment ..."
                fullWidth
                multiline
                rows={1}
                value={comments}
                onChange={(e) => setComments(e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                style={{ fontSize: '20px' }}
                variant="contained"
                color="primary"
                onClick={handlCommentSubmit}
                size="large"
              >
                Post
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  )
}


export default ForecastingSlider
