import React, { useEffect, useState } from 'react';
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const NewsCard = styled(Card)({
  display: 'flex',
  backgroundColor: '#000', // dark background
  marginBottom: '8px',
  '&:last-child': {
    marginBottom: 0,
  },
});

const NewsMedia = styled(CardMedia)({
  width: 7000, // set image width
  backgroundSize: 'cover',
  height: 500,
});

const NewsDetails = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  color: '#fff', // text color white
});

const Title = styled(Typography)({
  fontWeight: 'bold',
  color: 'green', // title color green
});

const Description = styled(Typography)({
  color: '#fff', // description color white
});

const NeonBorderCard = styled(Card)(({ theme }) => ({
  maxWidth: 1070,

  backgroundColor: theme.palette.grey[900],
  borderRadius: '0px',
  boxShadow: `
      0 0 8px rgba(0, 255, 0, 0.8),
      inset 0 0 8px rgba(0, 255, 0, 0.8),
      0 2px 2px rgba(0, 255, 0, 0.8)
    `,
  border: '1px dashed rgba(255, 255, 255, 0.6)',

}));

const NewsTop = ({
  title, description, images, newsUrl,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (images.length > 1) {
      const intervalId = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 4500);

      return () => clearInterval(intervalId);
    }
  }, [images.length]);


  return (
    <NeonBorderCard>
      <NewsCard onClick={() => newsUrl && window.open(newsUrl, '_blank')}>
        <CardActionArea style={{ display: 'flex' }}>
          <NewsMedia image={images[currentImageIndex]?.image} title={title} />
          <NewsDetails>
            <Title variant="subtitle1" gutterBottom>
              {title}
            </Title>
            <Description variant="body2">
              {description}
            </Description>
          </NewsDetails>
        </CardActionArea>
      </NewsCard>
    </NeonBorderCard>
  )
};



export default NewsTop;
