import React, { useState } from 'react'
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  Button,
} from '@mui/material'
import { NavLink } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import AccountMenu from '../../Profile/Profile'
import Logo from '../../../images/logo.svg'
import './Header.css'

import { isLoggedIn, tokenActive } from '../../../helper/auth'


const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography
        color="black"
        variant="h6"
        component="div"
        sx={{ flexGrow: 1, my: 2 }}
      >
        <NavLink to="/">
          <img src={Logo} alt="logo" height="80" width="200" />
        </NavLink>
      </Typography>
      <Divider />
    </Box>
  )

  return (
    <Box>
      <AppBar
        color="default"
        elevation={0}
        component="nav"
        sx={{
          borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
          bgcolor: '#FCFCFC',
          zIndex: 1100,
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            <NavLink to="/">
              <img
                src={Logo}
                alt="logo"
                height="100%"
                width="200"
                style={{ paddingBottom: '10px', paddingTop: '15px' }}
              />
            </NavLink>
          </Typography>
          <Box sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'center' }}>
            <NavLink to="/">
              <img src={Logo} alt="logo" height="100%" width="200" style={{ paddingBottom: '10px', paddingTop: '15px' }} />
            </NavLink>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
              '& .navigation-menu': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                listStyleType: 'none',
                margin: 0,
                padding: 0,
                '& li': {
                  padding: '0 5px',
                  // fontSize: '1.05rem',
                },
                '& a': {
                  textDecoration: 'none',
                  color: 'inherit',
                },
              },
            }}
          >
            <ul className="navigation-menu">
              <li>
                <NavLink activeclassname="active" to="/">
                  HOME
                </NavLink>
              </li>
              <li>
                <NavLink to="/questions">QUESTIONS</NavLink>
              </li>
              <li>
                <NavLink to="/tournaments">TOURNAMENTS</NavLink>
              </li>
              <li>
                <NavLink to="/training">TRAINING</NavLink>
              </li>
              <li>
                <NavLink to="/faq">FAQ</NavLink>
              </li>
            </ul>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              justifyContent: 'center',
            }}
          >
            {isLoggedIn() && tokenActive() ? (
              <AccountMenu />
            ) : (
              <>
                <Button
                  variant="contained"
                  component={NavLink}
                  to="/login"
                  sx={{
                    padding: '10px 40px',
                    background: '#2ece57',
                  }}
                >
                  Login
                </Button>
                <Button
                  variant="contained"
                  component={NavLink}
                  to="/register"
                  sx={{ ml: 2, padding: '10px 40px', background: '#6a3fb2' }}
                >
                  Register
                </Button>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: '240px',
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Toolbar />
      </Box>
    </Box>
  )
}


export default Header
