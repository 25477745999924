import * as React from 'react'
import {
  Button,
  Menu,
  Avatar,
  Box,
  MenuItem,
  ListItemIcon,
  IconButton,
  Tooltip,
} from '@mui/material'
import { NavLink } from 'react-router-dom'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Settings from '@mui/icons-material/Settings'
import { deepPurple } from '@mui/material/colors'
import { logout, getUserName } from '../../helper/auth'


const Profile = () => {

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const userName = getUserName()

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="medium"
            sx={{ ml: 4 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ background: '#FFF', width: 50, height: 50 }}>
              <img
                src={`https://api.dicebear.com/7.x/rings/svg?seed=${userName}`}
                alt={`Identicon for ${userName}`}
              />
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar to="/account" sx={{ color: deepPurple[500], bgcolor: 'white' }}>
            <ManageAccountsIcon />
          </Avatar>
          <NavLink to="/account" style={{ textDecoration: 'none' }}>My Account</NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Button
            variant="contained"
            onClick={() => logout()}
            to="/register"
            sx={{ padding: '10px 40px', background: '#2ece57' }}
          >
            Logout
          </Button>
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}


export default Profile
