import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  Container,
} from '@mui/material';
import { toast } from 'react-toastify';
import Pagination from '@mui/material/Pagination'
import { useNavigate } from 'react-router-dom';

import PageNotFound from '../PageNotFound/PageNotFound';
import Filters from '../../../components/Filters'
import Layout from '../../../components/Layout/Layout';
import { fetchQuestions } from '../../../queries/questions.query';
import { isLoggedIn } from '../../../helper/auth';
import QuestionList from './QuestionList';
import SearchBar from '../../../components/SearchBar/SearchBar'

const QuestionsPage = () => {
  const navigate = useNavigate()
  const [sort, setSort] = useState('trending');
  const [status, setStatus] = useState('OPEN');
  const [totalPage, setTotalPage] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedDates, setSelectedDates] = useState([null, null]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0)
    const startDate = selectedDates[0]?.toISOString()?.split('T')[0];
    const endDate = selectedDates[1]?.toISOString()?.split('T')[0];

    fetchQuestions({
      page,
      status,
      sort,
      startDate,
      endDate,
    })
      .then((data) => {
        setQuestions(data?.questions);
        setTotalPage(data?.pages);
      })
      .catch((error) => {
        if (error.message === 'Session expired') {
          toast.error('Your session has expired! Please log in again.')
          localStorage.clear()
          navigate('/login')
        } else {
          toast.error(error.message || 'Error Fetching Questions')
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, status, selectedDates, sort]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearch = (filteredQuestions) => {
    setQuestions(filteredQuestions);
  };

  if (!isLoggedIn()) {
    localStorage.clear()
    navigate('/login')
    return null
  }

  let content;
  if (loading) {
    content = <Typography>Loading...</Typography>;
  } else if (questions?.length === 0) {
    content = <Typography>No questions found.</Typography>;
  } else {
    content = (
      <>
        <QuestionList questions={questions} searchQuery={searchQuery} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 2,
            mb: 4,
          }}
        >
          <Pagination
            count={totalPage}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      </>
    );
  }

  return (
    <Layout>
      {isLoggedIn() ? (
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Filters
                status={status}
                setStatus={setStatus}
                sort={sort}
                setSort={setSort}
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
              />
            </Grid>
            <Grid item xs={12} md={9} style={{ 'padding-top': '50px' }}>
              <SearchBar
                data={questions}
                onSearch={handleSearch}
                setSearchQuery={setSearchQuery}
              />
              {content}
            </Grid>
          </Grid>
        </Container>
      ) : (
        <PageNotFound
          message1="Please Login"
          message2="This page is only accessible to authenticated users"
        />
      )}
    </Layout>
  );
};

export default React.memo(QuestionsPage)
