import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Grid,
  Card,
  Box,
  CardContent,
  Typography,
  Button,
  CardActions,
  CardMedia,
  Container,
  Pagination,
} from '@mui/material';
import { toast } from 'react-toastify';
import IconButton from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { isLoggedIn } from '../../../helper/auth'
import Filters from '../../../components/Filters'
import Layout from '../../../components/Layout/Layout'
import PageNotFound from '../PageNotFound/PageNotFound'
import { fetchTournaments } from '../../../queries/tournaments.query'

const cardStyles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    margin: '0px',
    border: '1px solid #FCFCFC',
    borderRadius: '10px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    backgroundColor: '#FCFCFC',
    color: '#3F256A',
    overflow: 'hidden',
    '&:hover': {
      boxShadow: '0px 2px 4px rgba(103, 58, 183, 0.35)',
    },
  },
  media: {
    position: 'relative',
    height: 0,
    paddingTop: '56.25%',
  },
  title: {
    color: '#3F256A',
    textDecoration: 'none',
    fontWeight: 'bold',
    lineHeight: '1.5em',
    minHeight: '3em',
    marginBottom: '0.5em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  content: {
    padding: '16px',
    flexGrow: 1,
    minHeight: '250px',
    marginBottom: '-1em',
    marginTop: '0px',
    '& > :nth-of-type(2)': {
      marginTop: '16px',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
    alignItems: 'center',
  },
  closingDate: {
    padding: '20px 16px 0px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    backgroundColor: '#6A3FB2',
    color: '#FFF',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#7e57c2',
    },
  },
  shareButton: {
    borderRadius: '5px',
    color: '#FFF',
    minWidth: '40px',
    height: '40px',
    backgroundColor: '#6A3FB2',
    '&:hover': {
      backgroundColor: '#7e57c2',
    },
  },
  questionsButton: {
    backgroundColor: '#6A3FB2',
    color: '#FFF',
    fontSize: '1rem',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#7e57c2',
    },
  },
  dateIcon: {
    verticalAlign: 'middle',
    marginRight: '5px',
  },
  dateText: {
    display: 'flex',
    alignItems: 'center',
  },
};

const TournamentCard = ({ tournament }) => {
  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      toast.success('URL copied to clipboard', {
        style: {
          backgroundColor: '#9575CD',
          color: '#F5F5F5',
        },
      });
    }, (err) => {
      toast.error('Failed to copy URL: ', err);
    });
  };

  return (
    <Card sx={cardStyles.card}>
      <CardMedia image={tournament.image} sx={cardStyles.media} component={Link} to={`/tournament/${tournament?.id}`} />
      <CardContent sx={cardStyles.content}>
        <Typography variant="h5" component={Link} sx={cardStyles.title} to={`/tournament/${tournament?.id}`}>
          {tournament.title}
        </Typography>
        <Typography sx={cardStyles.description}>
          {tournament.description.slice(0, 165)}
          {tournament.description.length > 165 ? '...' : ''}
        </Typography>
      </CardContent>
      <Box sx={cardStyles.closingDate}>
        <Typography variant="subtitle1" sx={cardStyles.dateText}>
          <CalendarTodayIcon sx={cardStyles.dateIcon} />
          Closes {new Date(tournament.ending_at).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })}
        </Typography>
      </Box>
      <CardActions sx={cardStyles.actions}>
        <IconButton
          sx={cardStyles.shareButton}
          onClick={() => copyToClipboard(window.location.href.replace('tournaments', 'tournament/') + tournament.id)}
          aria-label="share"
        >
          <ShareIcon />
        </IconButton>
        <Button
          component={Link}
          to={`/tournament/${tournament?.id}`}
          sx={cardStyles.questionsButton}
        >
          {tournament?.questions?.length} {tournament?.questions?.length === 1 ? 'Question' : 'Questions'}
        </Button>
      </CardActions>
    </Card>
  );
};

const Tournaments = () => {
  const navigate = useNavigate()

  const [order, setOrder] = useState('desc')
  const [status, setStaus] = useState('OPEN')
  const [totalPage, setTotalPage] = useState([])
  const [tournaments, setTournaments] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [selectedDates, setSelectedDates] = useState([null, null])

  useEffect(() => {
    window.scrollTo(0, 0)
    const startDate = selectedDates[0]?.toISOString()?.split('T')[0]
    const endDate = selectedDates[1]?.toISOString()?.split('T')[0]

    fetchTournaments({
      page, status, order, startDate, endDate,
    })
      .then((data) => {
        setTournaments(data?.tournaments)
        setTotalPage(data?.pages)
      })
      .catch(error => {
        if (error.message === 'Session expired') {
          toast.error('Your session has expired! Please log in again.')
          localStorage.clear()
          navigate('/login')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [page, status, selectedDates, order])

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  if (!isLoggedIn()) {
    localStorage.clear()
    navigate('/login')
    return null
  }

  if (loading) {
    return <PageNotFound message1="Loading..." />
  }

  if (tournaments?.length === 0) {
    return <PageNotFound message="No tournaments available" />
  }

  return (
    <Layout>
      <Container maxWidth="lg" sx={{ padding: '20px', backgroundColor: '#F5F5F5' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Filters
              status={status}
              setStatus={setStaus}
              order={order}
              setOrder={setOrder}
              selectedDates={selectedDates}
              setSelectedDates={setSelectedDates}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container spacing={3}>
              {tournaments?.map((tournament) => (
                <Grid item xs={12} sm={6} md={4} key={tournament.id}>
                  <TournamentCard tournament={tournament} />
                </Grid>
              ))}
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 4,
              }}
            >
              <Pagination
                count={totalPage}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default Tournaments
