import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  Box,
  Button,
  Backdrop,
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Divider,
  Tab,
  Tabs,
} from '@mui/material'
import { useNavigate, Link } from 'react-router-dom'

import Layout from '../../../components/Layout/Layout'
import fetchProfile from '../../../queries/profile.query'
import SemiArcPieChart from '../../../components/Charts/PieChart/SemiArcChart'
import EditModalDialog from './UpdateProfileDialog'
import PageNotFound from '../PageNotFound/PageNotFound'

import dateFormater from '../../../helper/utils'
import { isLoggedIn, getUserName } from '../../../helper/auth'
import './Account.css'
import {
  updatePassword,
  updateUsername,
} from '../../../mutations/forecast/comment.mutation'


const styles = {
  container: {
    padding: '96px',
  },
  leftColumn: {
    color: 'white',
    padding: '16px',
    borderRadius: '10px',
  },
  rightColumn: {
    padding: '0px 96px',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#3F256A',
    padding: '16px',
    margin: '8px 0',
    border: '1px solid #FCFCFC',
    borderRadius: '10px',
    fontSize: '20px',
  },
  radioLabel: {
    color: 'black',
  },
  filters: {
    marginTop: 5,
    color: '#000',
    background: '#FFF',
  },
  column: {
    padding: '2px',
  },
  menuButton: {
    padding: '10px',
    // background: '#DDD5F3',
    background: '#CEC2EB',
    marginRight: '5px',
    fontSize: '25px',
    color: 'white',
  },
  activeButton: {
    background: '#6a3fb2',
  },
  tabsHeader: {
    backgroundColor: '#EDE7F6',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px 15px',
    cursor: 'pointer',
    borderRadius: '10px',
    border: '1px solid rgba(0, 0, 0, 0.05)',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    color: '#333',
    fontWeight: 'bold',
  },
  stickyCard: {
    position: 'sticky',
    top: '135px',
    zIndex: 1000,
    borderRadius: '10px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    backgroundColor: '#FCFCFC',
    minHeight: '10px',
    marginBottom: '24px',
  },
}


const CategoriesComponent = ({ categories }) => {
  if (categories) {
    const items = Object.entries(categories);
    const sortedItems = items.sort((a, b) => b[1] - a[1]);
    const sortedData = Object.fromEntries(sortedItems);

    return (
      <CardContent>
        <List>
          {Object.entries(sortedData).map(([label, value]) => (
            <ListItem key={label}>
              <ListItemText primary={label} />
              <ListItemSecondaryAction>{value}</ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </CardContent>
    );
  }
}

const AnswerComponent = ({ forecasts }) => {
  return (
    forecasts && typeof forecasts === 'object' && Object.entries(forecasts).map(([key, value]) => (
      <Typography
        variant="body1"
        component="span"
        style={styles.answerContainer}
        key={key}
      >
        {key}: {value}
        <br />
      </Typography>
    ))
  )
}

const Account = () => {
  const navigate = useNavigate()
  const [isEditModalOpen, setEditModalOpen] = useState(false)
  const [activeTab, setActiveTab] = useState('username')
  const [newUsername, setNewUsername] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [passwordVisible, setPasswordVisible] = useState(false)

  const handleEditModalOpen = (tab) => {
    setActiveTab(tab)
    setEditModalOpen(true)
  }

  const handleEditModalClose = () => {
    setEditModalOpen(false)
  }

  const handleFieldChange = (event) => {
    if (activeTab === 'username') {
      setNewUsername(event.target.value)
    } else {
      setNewPassword(event.target.value)
    }
  }

  const handleSaveChanges = async () => {
    try {
      if (activeTab === 'username') {
        toast.info(`Are you sure to update your Username? ${newUsername}`)
        updateUsername({ username: newUsername })
        handleEditModalClose()
        toast.success('Username Updated Successfully')
        window.location.reload()
      } else {
        toast.info('Are you sure to update your password?')
        updatePassword({ password: newPassword })
        handleEditModalClose()
        toast.success('Password Updated Successfully')
      }
    } catch (err) {
      toast.error('Error updating the Paasword or Username')
    }
  }

  const [profileData, setProfileData] = useState([])
  const [loading, setLoading] = useState(false)
  const userName = getUserName()

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchProfile()
      .then((data) => {
        setProfileData(data)
      })
      .catch(error => {
        if (error.message === 'Session expired') {
          toast.error('Your session has expired! Please log in again.')
          localStorage.clear()
          navigate('/login')
        } else {
          toast.error(error.message || 'Error fetching Profile')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const questions = profileData?.predictions?.forecasts
  const profile = profileData?.profile
  const predictions = profileData?.predictions
  const comments = profileData?.comments

  const [tabValue, setTabValue] = useState(0)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <Layout>
      {isLoggedIn() ? (
        <main>
          <section className="account-header-section">
            <Container>
              <Grid container>
                <Grid item xs={4} md={4} className="account-image-grid" align="center">
                  <img
                    src={`https://api.dicebear.com/7.x/rings/svg?seed=${userName}`}
                    alt="Logo"
                    width="50%"
                    style={{ display: 'inline-block', height: '100%', verticalAlign: 'middle' }}
                  />
                </Grid>
                <Grid item xs={2} md={8} className="account-text-grid">
                  <Typography variant="h6" style={{ fontSize: '18px' }}><strong>Name:</strong> {profile?.name}</Typography>
                  <Typography variant="h6" style={{ fontSize: '18px' }}>
                    <strong>Username:</strong> {profile?.username}
                  </Typography>
                  <Typography variant="h6" style={{ fontSize: '18px' }}>
                    <strong>Email:</strong> {profile?.email}
                  </Typography>
                  <Typography variant="h6" style={{ fontSize: '18px' }}>
                    <strong>Last Logged In:</strong> {dateFormater(profile?.last_login)}
                  </Typography>
                  <Typography variant="h6" style={{ fontSize: '18px' }}>
                    <strong>Created At:</strong> {dateFormater(profile?.created_at)}
                  </Typography>
                  <br />
                  <Button
                    variant="contained"
                    onClick={() => handleEditModalOpen('username')}
                    sx={{
                      padding: '10px 40px',
                      background: '#673AB7',
                      marginRight: '10px',
                      marginBottom: '20px',
                      width: '40%',
                    }}
                  >
                    Update details
                  </Button>
                  <br />
                  <EditModalDialog
                    isOpen={isEditModalOpen}
                    onClose={handleEditModalClose}
                    activeTab={activeTab}
                    handleEditModalOpen={handleEditModalOpen}
                    handleFieldChange={handleFieldChange}
                    handleSaveChanges={handleSaveChanges}
                    newUsername={newUsername}
                    newPassword={newPassword}
                    passwordVisible={passwordVisible}
                    setPasswordVisible={setPasswordVisible}
                  />
                </Grid>
              </Grid>
            </Container>
          </section>
          <section>
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Card sx={{ ...styles.stickyCard }}>
                    <CardHeader
                      title={(
                        <Typography variant="h5" component="div" align="center" style={{ marginTop: '20px' }}>
                          <strong>Category Distribution</strong>
                        </Typography>
                      )}
                    />
                    <CardContent>
                      <SemiArcPieChart categories={predictions?.categories} />
                      <CategoriesComponent
                        categories={predictions?.categories}
                      />
                      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {' '}
                        Your Total Forecasts: {predictions?.count}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={8}>
                  <Tabs sx={styles.tabsHeader} value={tabValue} onChange={handleTabChange} style={styles.leaderboardHeader} aria-label="leaderboard tabs">
                    <Tab variant="Large" label="Forecasts" />
                    <Tab label="Comments" />
                  </Tabs>

                  {tabValue === 0 && (
                    <>
                      <Typography
                        variant="h6"
                        sx={{
                          height: '50px',
                          lineHeight: '50px',
                          textAlign: 'center',
                          display: 'inline-block',
                          verticalAlign: 'middle',
                          padding: '10px',
                          fontWeight: 'bold',
                        }}
                      >
                        Your Latest Forecast Activity
                      </Typography>
                      <List>
                        {questions?.map((question) => (
                          <ListItem
                            key={question?.created_at}
                            component={Link}
                            to={`/questions/${question?.id}`}
                            sx={{
                              ...styles.listItem,
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
                              marginBottom: 2,
                              backgroundColor: '#FCFCFC',
                            }}
                          >
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                              {question?.question}
                            </Typography>
                            {!loading && (
                              <Typography variant="body1" style={{ color: 'black' }}>
                                <AnswerComponent forecasts={question?.answer} />
                              </Typography>
                            )}
                            <Typography variant="body1" style={{ color: 'black' }}>
                              Rationale: {question?.rationale || 'NA'}
                            </Typography>
                            <Divider sx={{ width: '100%', my: 1 }} />
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                              <Box>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: 16 }}>
                                  Comments: <span style={{ fontWeight: 'normal', color: 'black' }}>{question?.comments}</span>
                                  <br />Category: <span style={{ fontWeight: 'normal', color: 'black' }}>{question?.category}</span><br />
                                  Closing Date: <span style={{ fontWeight: 'normal', color: 'black' }}>{dateFormater(question?.ending_at)}</span>
                                </Typography>
                              </Box>
                              <Box>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: 16 }}>
                                  <br /><br />Status: <span style={{ color: question?.status === 'OPEN' ? '#2ece57' : '#34aadc' }}>{question?.status}</span>
                                </Typography>
                              </Box>
                            </Box>
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )}

                  {tabValue === 1 && (
                    <>
                      <Typography
                        variant="h6"
                        sx={{
                          height: '50px',
                          lineHeight: '50px',
                          textAlign: 'center',
                          display: 'inline-block',
                          verticalAlign: 'middle',
                          padding: '10px',
                          fontWeight: 'bold',
                        }}
                      >
                        Your Latest Comment Activity
                      </Typography>
                      <List>
                        {comments?.map((comment) => (
                          <ListItem
                            key={comment?.created_at}
                            component={Link}
                            to={`/questions/${comment?.question_id}#:~:text=${comment?.comment}`}
                            sx={{
                              ...styles.listItem,
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
                              marginBottom: 2,
                              backgroundColor: '#FCFCFC',
                            }}
                          >
                            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                              {comment?.question}
                            </Typography>
                            <Typography variant="body1" sx={{ color: 'black' }}>
                              Comment: {comment?.comment}
                            </Typography>
                            <Divider sx={{ width: '100%', my: 1 }} />
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                              <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: 16 }}>
                                Created At: <span style={{ fontWeight: 'normal', color: 'black' }}>{dateFormater(comment?.created_at)}</span>
                              </Typography>
                              <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: 16 }}>
                                Upvotes: <span style={{ fontWeight: 'normal', color: 'black' }}>{comment?.upvotes}</span>
                              </Typography>
                            </Box>
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )}
                </Grid>
              </Grid>
            </Container>
          </section>
          {isEditModalOpen && (
            <Backdrop
              sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                color: '#fff',
                backdropFilter: 'blur(8px)',
              }}
              open={isEditModalOpen}
              onClick={handleEditModalClose}
            />
          )}
        </main>
      ) : (
        <PageNotFound
          message1="Please Login"
          message2="This page is only accessible to authenticated users"
        />
      )}
    </Layout>
  )
}


export default Account
