import React from 'react';
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  Paper,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const styles = {
  stickyFilterContainer: {
    padding: '20px',
    position: 'sticky',
    top: '135px',
    zIndex: 1000,
    borderRadius: '10px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    backgroundColor: '#FCFCFC',
    minHeight: '10px',
    marginBottom: '24px',
  },
  formControl: {
    width: '100%',
    marginBottom: '16px',
  },
  button: {
    marginTop: '24px',
    marginBottom: '8px',
    width: '100%',
    color: '#FFFFFF',
    padding: '10px 0',
  },
};

const Filters = ({
  status,
  setStatus,
  sort,
  setSort,
  selectedDates,
  setSelectedDates,
}) => {
  return (
    <Paper sx={styles.stickyFilterContainer} elevation={3}>
      <Typography
        variant="h6"
        sx={{ mb: 3, fontWeight: 'bold', textAlign: 'center' }}
      >
        Filter & Sort:
      </Typography>

      <Grid container spacing={2} direction="column">
        <Grid item>
          <FormControl fullWidth sx={styles.formControl}>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              label="Status"
            >
              <MenuItem value="OPEN">Open</MenuItem>
              <MenuItem value="PUBLISHED">Published</MenuItem>
              <MenuItem value="RESOLVED">Resolved</MenuItem>
              <MenuItem value="VOIDED">Voided</MenuItem>
              <MenuItem value="HIDDEN">Hidden</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl fullWidth sx={styles.formControl}>
            <InputLabel id="sort-label">Sort</InputLabel>
            <Select
              labelId="sort-label"
              value={sort}
              onChange={(e) => setSort(e.target.value)}
              label="Sort"
            >
              <MenuItem value="trending">Trending</MenuItem>
              <MenuItem value="new_to_old">New to Old</MenuItem>
              <MenuItem value="old_to_new">Old to New</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={selectedDates[0]}
              onChange={(date) => setSelectedDates([date, selectedDates[1]])}
              renderInput={(params) => (
                <TextField
                  label={params.label}
                  inputRef={params.inputRef}
                  inputProps={params.inputProps}
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={selectedDates[1]}
              onChange={(date) => setSelectedDates([selectedDates[0], date])}
              renderInput={(params) => (
                <TextField
                  label={params.label}
                  inputRef={params.inputRef}
                  inputProps={params.inputProps}
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Filters
