import { getToken } from './auth'


const getHeaders = () => {
  const headers = new Headers()
  headers.set('Content-Type', 'application/json')
  headers.set('Authorization', `Bearer ${getToken()}`)
  return headers
}

export default getHeaders
