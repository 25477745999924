import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Typography from '@mui/material/Typography'
import { toast } from 'react-toastify'

import Layout from '../../../components/Layout/Layout'
import Banner from '../../../images/background.png'
import { handleTokenAndStoreUserInfo } from '../../../helper/token_decode'
import { postToken, postAuthenticate } from '../../../mutations/Account/account.mutation'

const SignIn = () => {

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    const username = data.get('username')
    const password = data.get('password')

    if ( !username || !password ) {
      return
    }

    setLoading(true)

    postToken(username, password)
      .then((response) => {
        const accessToken = response.data.access
        handleTokenAndStoreUserInfo(accessToken)
        return postAuthenticate(data.get('username'), data.get('password'))
      })
      .then(() => {
        navigate('/')
      })
      .catch((error) => {
        toast.error(error.response?.data.detail || 'Internal Server Error Occured')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Layout>
      <Grid container component="main" sx={{ height: '100vh', display: 'flex', flexDirection: 'row' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${Banner})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: t => {
              if ( t.palette.mode === 'light' ) {
                return t.palette.grey[50]
              }
              return t.palette.grey[900]
            },
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              my: 14,
              mx: 10,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOpenIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="username"
                name="username"
                autoComplete="username"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                helperText="Password must be at least 8 characters long and include a special, a numeric, and an uppercase character!"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={handleTogglePasswordVisibility}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: '#6a3fb2',
                  color: '#fff',
                }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
              </Button>
              <Grid container>
                <Grid item>
                  <Typography variant="body2" sx={{ display: 'inline' }}>
                    Don't have an account?{' '}
                  </Typography>
                  <Link
                    href="/register"
                    variant="body2"
                    sx={{ color: '#6a3fb2', textDecoration: 'none' }}
                  >
                    Register
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  )
}


export default SignIn
