import React, { useEffect, useState } from 'react'
import {
  Grid,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import PageNotFound from '../PageNotFound/PageNotFound'
import Layout from '../../../components/Layout/Layout'
import SearchBar from '../../../components/SearchBar/SearchBar'
import fetchFaqs from '../../../queries/faqs.query'
import { isLoggedIn } from '../../../helper/auth'
import FaqList from './FaqList/FaqList'


const styles = {
  topText: {
    padding: '8px 0px 3px 0px',
    fontSize: '45px',
    fontWeight: 'bold',
    opacity: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  updatedDate: {
    padding: '2px 0px 30px 0px',
    fontSize: '22px',
    fontWeight: 'bold',
    opacity: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  filters: {
    padding: '1% 20%',
  },
  contactUs: {
    padding: '1% 20%',
    width: '100%',
  },
}


const EmailLink = styled('a')`
  color: white !important
  text-decoration: none
  margin-right: 15px
  font-size: 1em

  &:hover {
    color: #6a3fb2 !important
    text-decoration: underline
  }
`


const FaqPage = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [faqs, setFaqs] = useState([])
  const [filteredFaqs, setFilteredFaqs] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchFaqs()
      .then((data) => {
        setFaqs(data?.questions)
      })
      .catch(error => {
        if (error.message === 'Session expired') {
          toast.error('Your session has expired! Please log in again.')
          localStorage.clear()
          navigate('/login')
        } else {
          toast.error(error.message || 'Error fetching FAQs')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  let content
  if (loading) {
    content = <Typography>Loading...</Typography>
  } else if (faqs?.length === 0) {
    content = <Typography>No faqs found.</Typography>
  } else {
    content = <FaqList faqs={faqs} isLoading={loading} />
  }

  const handleSearchResults = (results) => {
    setFilteredFaqs(results)
  }


  let lastUpdated = new Date(0);
  if ( faqs && Object.keys(faqs) ) {
    Object.keys(faqs).forEach(faqKey => {
      faqs[faqKey]?.forEach(faq => {
        const updatedAt = new Date(faq?.modified_at);
        if (updatedAt > lastUpdated) {
          lastUpdated = updatedAt;
        }
      })
    })

  }

  return (
    <Layout>
      {isLoggedIn() ? (
        <div style={styles.filters}>
          <Typography style={styles.topText}>
            Frequently Asked Questions
          </Typography>
          <Typography style={styles.updatedDate}>
            Last Updated In { new Date(lastUpdated).toLocaleString('en-US', {
            month: 'long',
            year: 'numeric',
          })}
          </Typography>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={1} />
            <Grid item xs={12} sm={10}>
              <SearchBar data={faqs} onSearch={handleSearchResults} />
            </Grid>
          </Grid>
          <div style={styles.rightColumn}>
            {filteredFaqs?.length > 0 ? (
              <FaqList faqs={filteredFaqs} isLoading={loading} />
            ) : (
              content
            )}
            <Typography variant="h6" style={styles.contactUs}>Please contact us at <EmailLink href="mailto:sage-info@isi.edu">sage-info@isi.edu</EmailLink> for any questions</Typography>
          </div>
        </div>
      ) : (
        <PageNotFound
          message1="Please Login"
          message2="This page is only accessible to authenticated users"
        />
      )}
    </Layout>
  )
}


export default React.memo(FaqPage)
