import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Typography, Box } from '@mui/material'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from '@mui/lab'

import HorizontalBarGraph from '../Charts/BarChart/HorizontalBarChart'
import {
  upvoteComment,
} from '../../mutations/forecast/comment.mutation'

const styles = {
  timelineContainer: {
    height: '100vh',
    overflowY: 'auto',
    padding: '0 16px',
  },
  timelineItem: {
    '&:before': {
      display: 'none',
    },
  },
  timelineContent: {
    padding: '16px',
    paddingLeft: '24px',
  },
  timelineDot: {
    color: '#3f51b5',
    padding: '8px',
  },
  timelineConnector: {
    backgroundColor: '#9c27b0',
  },
  timelineOppositeContent: {
    flex: 0.1,
    padding: '0 16px',
  },
  rationale: {
    fontStyle: 'italic',
    fontSize: '0.8rem',
  },
  thumbIcon: {
    cursor: 'pointer',
    color: '#3f51b5',
    fontSize: '1.5 rem',
  },
}

const CustomTimelineItem = ({
  date,
  content,
  barGraphData,
  rationale,
  comment,
}) => {
  const [upvotes, setUpvotes] = useState(comment?.upvotes || 0);

  const handleUpvote = async () => {
    try {
      const response = await upvoteComment({ comment: comment.id })
      if (response.success) {
        toast.success('Upvote Successful');
        setUpvotes(prevUpvotes => prevUpvotes + 1);
      } else {
        toast.error(response?.message)
      }
    } catch (err) {
      toast.error('Error while Upvoting', err)
    }
  }

  return (
    <TimelineItem style={styles.timelineItem}>
      <TimelineOppositeContent style={styles.timelineOppositeContent}>
        <Typography color="textSecondary">{date}</Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot style={styles.timelineDot} />
        <TimelineConnector style={styles.timelineConnector} />
      </TimelineSeparator>
      <TimelineContent style={styles.timelineContent}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ width: '50%' }}>
            <Typography
              variant="subtitle2"
              component="h1"
              style={{ fontWeight: 'bold' }}
            >
              {content?.username}
            </Typography>
            {barGraphData && (
              <Typography style={styles.rationale}>{rationale}</Typography>
            )}

            {comment && (
              <>
                <Typography style={styles.rationale}>
                  {comment?.comment}
                </Typography>
                <Typography>
                  <ThumbUpIcon onClick={handleUpvote} style={styles.thumbIcon} /> {upvotes}
                </Typography>
              </>
            )}
          </Box>
          {barGraphData && (
            <Box sx={{ width: '50%' }}>
              <HorizontalBarGraph data={barGraphData} />
            </Box>
          )}
        </Box>
      </TimelineContent>
    </TimelineItem>
  )
}

export default CustomTimelineItem
