import getHeaders from '../../helper/header'


export const postComment = (data) => {
  const url = '/api/forecasts/comments/post/'
  const headers = getHeaders()

  return new Promise((resolve, reject) => {
    fetch(url, { method: 'POST', headers, body: JSON.stringify(data) })
      .then((response) => response.json())
      .then((responseData) => resolve(responseData))
      .catch((error) => reject(error))
  })
}


export const upvoteComment = (data) => {
  const url = '/api/forecasts/comments/upvote/'
  const headers = getHeaders()

  return new Promise((resolve, reject) => {
    fetch(url, { method: 'POST', headers, body: JSON.stringify(data) })
      .then((response) => response.json())
      .then((responseData) => resolve(responseData))
      .catch((error) => reject(error))
  })
}


export const updatePassword = (data) => {
  const url = '/api/accounts/change/password/'
  const headers = getHeaders()

  return new Promise((resolve, reject) => {
    fetch(url, { method: 'POST', headers, body: JSON.stringify(data) })
      .then((response) => response.json())
      .then((responseData) => resolve(responseData))
      .catch((error) => reject(error))
  })
}


export const updateUsername = (data) => {
  const url = '/api/accounts/change/username/'
  const headers = getHeaders()

  return new Promise((resolve, reject) => {
    fetch(url, { method: 'POST', headers, body: JSON.stringify(data) })
      .then((response) => response.json())
      .then((responseData) => resolve(responseData))
      .catch((error) => reject(error))
  })
}
