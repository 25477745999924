import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { toast } from 'react-toastify'

import Layout from '../../../components/Layout/Layout'
import Banner from '../../../images/background.png'
import { postRegister } from '../../../mutations/Account/account.mutation'

const Register = () => {
  const navigate = useNavigate()
  const [passwordVerification, setPasswordVerification] = useState('')
  const [passwordMismatch, setPasswordMismatch] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [passwordCriteriaMet, setPasswordCriteriaMet] = useState(true)

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev)
  }

  const checkPasswordCriteria = (password) => {
    const specialCharacterRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/
    const numericCharacterRegex = /\d/;
    const uppercaseCharacterRegex = /[A-Z]/

    const isPasswordValid = password.length >= 8
      && specialCharacterRegex.test(password)
      && numericCharacterRegex.test(password)
      && uppercaseCharacterRegex.test(password)

    return isPasswordValid
  }

  const handlePasswordCriteriaCheck = (event) => {
    const enteredPassword = event.target.value
    const isPasswordValid = checkPasswordCriteria(enteredPassword)
    setPasswordCriteriaMet(isPasswordValid)
  }

  const handlePasswordVerificationChange = (event) => {
    setPasswordVerification(event.target.value)
    setPasswordMismatch(false)
  }

  const handleAgreeToTermsChange = (event) => {
    setAgreedToTerms(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!agreedToTerms) {
      toast.error('You must agree to the Terms and Conditions.');
      return;
    }
    try {
      setLoading(true)

      const data = new FormData(event.currentTarget)
      const enteredPassword = data.get('password')

      if (enteredPassword !== passwordVerification) {
        setPasswordMismatch(true)
        setLoading(false)
        return
      }
      postRegister(data.get('name'), data.get('password'), data.get('email') )
        .then((response) => {
          if (response?.data?.success === true) {
            toast.success('Account Created')
            localStorage.clear()
            navigate('/login')
          } else {
            toast.error('Error Creating Account', response)
          }
        })

      localStorage.clear()
      navigate('/login')
    } catch (error) {
      if (error?.response && error?.response?.data) {
        toast.error(error.response.data.message)
      } else {
        toast.error('Registration failed. Please try again.')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Layout>
      <Grid container component="main" sx={{ height: '125vh', display: 'flex', flexDirection: 'row' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${Banner})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (theme) => {
              if (theme.palette.mode === 'light') {
                return theme.palette.grey[50]
              }
              return theme.palette.grey[900]
            },
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              my: 14,
              mx: 10,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <AccountCircleIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Register
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                fullWidth
                name="name"
                label="Username"
                id="name"
                autoComplete="name"
                required
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                onBlur={handlePasswordCriteriaCheck}
                helperText={(
                  <span style={{ color: passwordCriteriaMet ? 'inherit' : 'red', fontSize: '15px' }}>
                    {passwordCriteriaMet ? 'Password must be at least 8 characters long and include a special, a numeric, and an uppercase character!' : 'Password must be at least 8 characters long and include a special, a numeric, and an uppercase character!'}
                  </span>
                )}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="passwordVerification"
                label="Verify Password"
                type={showPassword ? 'text' : 'password'}
                id="passwordVerification"
                autoComplete="current-password"
                onChange={handlePasswordVerificationChange}
                sx={{ borderColor: passwordMismatch ? 'red' : 'inherit' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={handleTogglePasswordVisibility}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {passwordMismatch && (
                <Typography color="red" variant="caption">
                  Passwords do not match
                </Typography>
              )}
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email Address (optional)"
                name="email"
              />
              <FormControlLabel
                control={<Checkbox checked={agreedToTerms} onChange={handleAgreeToTermsChange} name="agreedToTerms" />}
                label={<span>I agree to the <a href="terms-and-conditions" target="_blank" style={{ color: '#6a3fb2', textDecoration: 'none' }} rel="noopener noreferrer">Terms and Conditions</a></span>}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: '#6a3fb2',
                  color: '#fff',
                }}
                disabled={passwordMismatch || loading || !agreedToTerms || !passwordCriteriaMet}
              >
                {loading ? <CircularProgress /> : 'Register'}{' '}
              </Button>
              <Grid container>
                <Grid item>
                  <Typography variant="body2" sx={{ display: 'inline' }}>
                    Already have an account?{' '}
                  </Typography>
                  <Link href="/login" variant="body2" sx={{ color: '#6a3fb2', textDecoration: 'none' }}>
                    Login
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Register
