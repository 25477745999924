import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  // AppBar,
  // Toolbar,
  Grid,
  Typography,
  CardMedia,
  IconButton,
  Button,
  Container,
  Card,
  CardContent,
  Box,
  Tab,
  Tabs,
  Pagination,
} from '@mui/material'
import ShareIcon from '@mui/icons-material/Share'
// import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'


import Layout from '../../../components/Layout/Layout'
import QuestionList from '../Questions/QuestionList'
import { isLoggedIn } from '../../../helper/auth'
import PageNotFound from '../PageNotFound/PageNotFound'
import LeaderboardTable from './LeaderboardTable'
import { fetchTournamentByID } from '../../../queries/tournaments.query'
import dateFormater from '../../../helper/utils';

const styles = {
  container: {
    marginTop: '50px',
    padding: '2%',
    maxWidth: '100%',
    backgroundColor: '#F5F5F5',
  },
  media: {
    height: 'auto',
    borderRadius: '4px',
    margin: 'auto',
    maxWidth: '100%',
    boxShadow: 'none',
  },
  detailBox: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '20px',
    margin: '20px 0',
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: 'none',
  },
  tab: {
    fontWeight: 'bold',
    color: '#333',
    flex: 1,
    textAlign: 'center',
  },
  button: {
    backgroundColor: '#6A3FB2',
    color: '#FFF',
    fontSize: '1rem',
    '& a': {
      color: 'white',
      textDecoration: 'none',
    },
    '&:hover': {
      backgroundColor: '#5d32a8',
    },
    borderRadius: '4px',
    textTransform: 'none',
    margin: '10px 0', // consistent margin for buttons
  },
  appBar: {
    paddingTop: '5px',
    borderTopRightRadius: '10px',
    borderTopLeftRadius: '10px',
    paddingBottom: '5px',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
  },
  tournamentHeader: {
    flexGrow: 1,
    fontWeight: 'bold',
  },
  tournamentImage: {
    height: '250px',
    width: '100%',
    backgroundSize: 'cover',
  },
  tournamentStats: {
    padding: '20px 0',
    backgroundColor: '#F5F5F5',
  },
  tournamentDetails: {
    padding: '16px 0',
    borderBottom: '1px solid #e0e0e0',
    marginBottom: '20px',
  },
  descriptionContainer: {
    padding: '20px',
    borderBottom: '1px solid #e0e0e0',
    marginBottom: '20px',
    backgroundColor: '#F5F5F5',
  },
  leaderboardToggleStyle: {
    backgroundColor: '#E8EAF6',
    padding: '10px',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  tabsHeader: {
    backgroundColor: '#F5F5F5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px 15px',
    cursor: 'pointer',
    borderRadius: '10px',
    color: '#333',
    fontWeight: 'bold',
    boxShadow: 'none',
  },
  leaderboardHeader: {
    backgroundColor: '#E8EAF6',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px 20px',
    cursor: 'pointer',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    color: '#333',
    fontWeight: 'bold',
    marginTop: '15px',
  },
  detailItem: {
    flex: 1,
    textAlign: 'center',
  },
  infoText: {
    fontWeight: 'bold',
    color: '#333',
  },
  normalText: {
    fontWeight: 'normal',
    color: '#666',
  },
  questionPaper: {
    padding: '20px',
    marginTop: '20px',
  },
};

const AttachmentDisplay = ({ attachments }) => {
  if (!attachments || attachments.length === 0) {
    return <Typography variant="body1">N/A</Typography>;
  }

  return (
    <Box>
      {attachments.map((attachment) => {
        switch (attachment.type) {
          case 'image':
            return (
              <Box key={attachment.id} mb={2}>
                <Typography
                  variant="subtitle1"
                  style={{
                    textAlign: 'left',
                    marginBottom: '10px',
                  }}
                >
                  <strong>{attachment.title}</strong>
                </Typography>
                <img
                  src={attachment.media}
                  alt={attachment.title}
                  style={{ width: '100%', borderRadius: '10px' }}
                />
              </Box>
            )
          case 'description':
            return (
              <Box key={attachment.id} mb={2}>
                <Typography variant="subtitle1"><strong>{attachment.title}</strong></Typography>
                <Typography variant="p">{attachment.media}</Typography>
              </Box>
            )
          case 'document':
            return (
              <Box key={attachment.id} mb={2}>
                <Button
                  component={Link}
                  to={attachment.media}
                  sx={styles.button}
                >
                  <span>Download {attachment.title}</span>
                </Button>
              </Box>
            )
          default:
            return null
        }
      })}
    </Box>
  )
}

const Tournament = ({ tournament }) => {

  const [tabValue, setTabValue] = useState(0)
  const [page, setPage] = useState(1);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const questions = tournament?.questions
  const resQuestions = questions.filter(question => question.status === 'RESOLVED');

  const questionsPerPage = 5;
  const totalPage = Math.ceil(questions.length / questionsPerPage);
  const indexFirst = (page - 1) * questionsPerPage;
  const currentTotalQuestions = questions.slice(indexFirst, indexFirst + questionsPerPage);
  const currentResolvedQuestions = resQuestions.slice(indexFirst, indexFirst + questionsPerPage);

  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      toast.success('URL copied to clipboard', {
        style: {
          backgroundColor: '#9575CD',
          color: '#F5F5F5',
        },
      });
    }, (err) => {
      toast.error('Failed to copy URL: ', err);
    });
  };

  const { questionId } = useParams();

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const index = tournament?.questions.findIndex(q => q.id.toString() === questionId);
    if (index >= 0) {
      setCurrentIndex(index);
    }
  }, [currentIndex, questionId, tournament?.questions]);

  return (
    <Container style={{ paddingTop: '50px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ borderRadius: '10px' }}>
            <CardMedia
              component="img"
              height="100%"
              image={tournament?.image}
              alt="Tournament"
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={8} style={{ marginTop: '-10px' }}>
          <CardContent>
            <Typography gutterBottom variant="h4" style={{ fontWeight: 'bold' }} component="h2">
              {tournament?.title}
              <IconButton
                onClick={() => copyToClipboard(window.location.href)}
                style={{
                  marginLeft: '10px',
                  color: '#6A3FB2',
                  width: '5%',
                }}
              >
                <ShareIcon />
              </IconButton>
            </Typography>
            <Typography gutterBottom variant="body1" color="textSecondary" component="p">
              {tournament?.description}
            </Typography>
            <br />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle1">
                  <strong>Start Date: </strong>
                  {dateFormater(tournament?.starting_at)}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>End Date: </strong>
                  {dateFormater(tournament?.ending_at)}
                </Typography>
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1"><strong>Forecasters</strong></Typography>
                <Typography variant="body2">{tournament?.forecasters || 0}</Typography>
              </Grid> */}
            </Grid>
          </CardContent>
        </Grid>
      </Grid>
      <Box style={styles.descriptionContainer}>
        <Tabs value={tabValue} onChange={handleTabChange} style={styles.tabsHeader} aria-label="leaderboard tabs">
          <Tab style={styles.tab} label="Overview" />
          <Tab style={styles.tab} label="Questions" />
          <Tab style={styles.tab} label="Resolved" />
          <Tab style={styles.tab} label="Leaderboard" />
        </Tabs>
        {tabValue === 0 && <AttachmentDisplay attachments={tournament?.attachments} />}
        {
          tabValue === 1 && (
            <>
              <QuestionList questions={currentTotalQuestions} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 4,
                }}
              >
                <Pagination
                  count={totalPage}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Box>
            </>
          )
        }
        {
          tabValue === 2 && (
            resQuestions.length > 0 ? (
              <>
                <QuestionList questions={currentResolvedQuestions} />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 4,
                  }}
                >
                  <Pagination
                    count={totalPage}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Box>
              </>
            ) : (
              <Typography variant="body1">Questions have not been resolved yet!</Typography>
            )
          )
        }
        {tabValue === 3 && <LeaderboardTable />}
      </Box>
    </Container>
  )
}

const TournamentDetail = () => {
  const { tournamentId } = useParams()
  const [tournament, setTournament] = useState(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    fetchTournamentByID(tournamentId)
      .then((data) => {
        setLoading(true)
        setTournament(data?.tournament)
      }).catch((error) => {
        if (error.message === 'Session expired') {
          toast.error('Your session has expired! Please log in again.')
          localStorage.clear()
          navigate('/login')
        } else {
          toast.error(error.message || `Error fetching tournament with id ${tournamentId}`)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [tournamentId])

  if (!isLoggedIn()) {
    localStorage.clear()
    navigate('/login')
    return null
  }

  if (loading) {
    return <PageNotFound message1="Loading..." />
  }

  if (!tournament) {
    return (
      <PageNotFound
        message={`Tournament With ID : ${tournamentId} has not been posted yet`}
      />
    )
  }

  return (
    <Layout>
      <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
        <Tournament tournament={tournament} />
      </div>
    </Layout>
  )
}


export default TournamentDetail
