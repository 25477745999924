import React from 'react';
import {
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const UpdateProfileDialog = ({
  isOpen,
  onClose,
  activeTab,
  handleEditModalOpen,
  handleFieldChange,
  handleSaveChanges,
  newUsername,
  newPassword,
  passwordVisible,
  setPasswordVisible,
}) => {
  const theme = useTheme();

  const commonButtonStyle = (
    bgColor,
    textColor = theme.palette.text.primary,
    hoverColor = theme.palette.secondary,
  ) => ({
    background: bgColor,
    color: textColor,
    fontSize: '16px',
    margin: theme.spacing(1),
    '&:hover': {
      color: hoverColor,
      background: bgColor,
    },
  });

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg">
      <DialogTitle sx={{ backgroundColor: theme.palette.primary.main, textAlign: 'center' }}>
        <Button
          variant="contained"
          onClick={() => handleEditModalOpen('username')}
          sx={commonButtonStyle('#FFFFFF', theme.palette.primary.main)}
        >
          Update Username
        </Button>
        <Button
          variant="contained"
          onClick={() => handleEditModalOpen('password')}
          sx={commonButtonStyle('#FFFFFF', theme.palette.primary.main)}
        >
          Update Password
        </Button>
      </DialogTitle>
      <DialogContent>
        <TextField
          label={activeTab === 'username' ? 'New Username' : 'New Password'}
          required
          fullWidth
          type={activeTab === 'password' && passwordVisible ? 'text' : 'password'}
          value={activeTab === 'username' ? newUsername : newPassword}
          onChange={handleFieldChange}
          margin="normal"
          helperText={activeTab === 'password' && 'Password must be at least 8 characters long and include a special, a numeric, and an uppercase character!'}
          InputProps={activeTab === 'password' && {
            endAdornment: (
              <IconButton
                onClick={() => setPasswordVisible(!passwordVisible)}
                edge="end"
              >
                {passwordVisible ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ),
          }}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', padding: theme.spacing(2) }}>
        <Button onClick={onClose} sx={commonButtonStyle('#673AB7', '#FFFFFF')}>
          Cancel
        </Button>
        <Button onClick={handleSaveChanges} sx={commonButtonStyle('#E74C3C', 'white')}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateProfileDialog;
