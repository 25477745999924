import React, { useState } from 'react';

const ImageWithFallback = ({
  src, image, alt, height, style,
}) => {
  const [imgSrc, setImgSrc] = useState(src);

  const onError = () => {
    setImgSrc(image);
  };

  return (
    <img
      src={imgSrc}
      alt={alt}
      height={height}
      style={style}
      onError={onError}
    />
  );
};

export default ImageWithFallback;
