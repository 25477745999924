import getHeaders from '../helper/header'


const handleFetch = async (url, options = {}) => {
  const response = await fetch(url, options);
  if (response.status === 401) {
    throw new Error('Session expired');
  }
  if (!response.ok) {
    throw new Error('Network response was not ok.');
  }
  return response.json();
};


export const fetchTournaments = ({
  page,
  status,
  order,
  startDate,
  endDate,
}) => {
  let url = '/api/forecasts/tournaments/list?'

  if (page !== undefined) {
    url += `page=${page}&`
  }
  if (status !== undefined) {
    url += `status=${status}&`
  }
  if (order !== undefined) {
    url += `order=${order}&`
  }
  if (startDate !== undefined) {
    url += `start=${startDate}&`
  }
  if (endDate !== undefined) {
    url += `end=${endDate}&`
  }
  const headers = getHeaders()

  return handleFetch(url, { method: 'GET', headers });
}

export const fetchFeaturedTournament = () => {
  const url = 'api/forecasts/tournaments/featured/'

  const headers = getHeaders()

  return new Promise((resolve, reject) => {
    fetch(url, { method: 'GET', headers })
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error))
  })
}

export const fetchTournamentByID = (tournamentId) => {
  const url = `/api/forecasts/tournaments/fetch/${tournamentId}`
  const headers = getHeaders()

  return handleFetch(url, { method: 'GET', headers });
}



export const fetchTournamentLeaderboard = (tournamentId) => {
  const url = `/api/forecasts/tournaments/leaderboard/${tournamentId}`
  const headers = getHeaders()

  return handleFetch(url, { method: 'GET', headers });
}
