import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ImageWithFallback from '../FallbackImage'
import QuestionPlaceholderImage from '../../images/question_placeholder_image.png'

const styles = {
  container: {
    marginTop: '66px',
  },
  imageGrid: {
    textAlign: 'center',
  },
  image: {
    width: '100%',
    maxWidth: '100%',
    height: '225px',
  },
  textGrid: {
    textAlign: 'left',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '24px',
  },
  infoText: {
    fontWeight: 'bolder',
    fontSize: '16px',
  },
  normalText: {
    fontSize: '16px',
  },
  shareGrid: {
    textAlign: 'center',
  },
  attachment: {
    height: '100%',
  },
  fileImage: {
    maxHeight: '500px',
    height: '100%',
    width: '100%',
    paddingRight: '50px',
  },
  attachmentLabel: {
    padding: '0px 0px 20px 0px',
    color: 'purple',
    fontSize: '25px',
  },
}

const Question = ({ question }) => {
  const attachmentsSize = question?.attachments?.length
  return (
    <>
      <Grid container style={styles.container} spacing={2}>
        <Grid item xs={12} md={3} style={styles.imageGrid}>
          <ImageWithFallback
            key={question?.image}
            src={question?.image}
            image={QuestionPlaceholderImage}
            alt="Question Detail"
            style={styles.image}
          />
        </Grid>
        <Grid item xs={12} md={9} style={styles.textGrid}>
          <Typography variant="h7" style={styles.title}>
            {question.title}
          </Typography>
          <Typography variant="body2" style={{ textAlign: 'justify', marginTop: '10px' }}>
            {question?.description && (
              <span style={styles.normalText}>{question.description}</span>
            )}
          </Typography>
          <Typography variant="body2" style={{ marginTop: '10px' }}>
            <span style={styles.infoText}>Category: </span>
            <span style={styles.normalText}>{question.category}</span>
          </Typography>
          <Typography variant="body2">
            <span>
              <span style={styles.infoText}>Started On: </span>
              <span style={styles.normalText}>
                {new Date(question?.starting_at).toLocaleString('en-US', {
                  month: 'long',
                  day: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  timeZoneName: 'short',
                })}
              </span>
            </span>
            <span style={{ float: 'right' }}>
              <span style={styles.infoText}>Closing At: </span>
              <span style={styles.normalText}>
                {new Date(question?.ending_at).toLocaleString('en-US', {
                  month: 'long',
                  day: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  timeZoneName: 'short',
                })}
              </span>
            </span>
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ paddingTop: '50px' }}>
        {question?.attachments?.map((attachment) => (
          <Grid
            item
            xs={12}
            md={12 / attachmentsSize}
            style={styles.attachment}
          >
            <ImageWithFallback
              src={attachment?.file}
              image={QuestionPlaceholderImage}
              alt={attachment?.label}
              style={styles.fileImage}
            />
            <Typography style={styles.attachmentLabel}>
              {attachment?.label}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default Question
